import './App.css';
import Home from './pages';
import { I18nextProvider } from "react-i18next";
import React from 'react';
import i18next from "./i18n";

import { BrowserRouter, Route, Routes } from "react-router-dom";

// import ErrorPage from './components/error/page';
import Features from './pages/features';
import Process from './pages/process';
import CookiesPage from './pages/cookies';
import PrivacyPolicy from './pages/privacityPolicy';
import FrequentlyAskedQuestionSection from './pages/faq';
import SuccessView from './pages/success';
import PricingComparisonPage from './pages/pricingComparison';
import DoctorProfile from './pages/doctorProfile';

import TagManager from 'react-gtm-module'
import ErrorPage from './components/error/page';
import BookingFrame from './components/BookingFrame';

const tagManagerArgs = {
  gtmId: 'GTM-NM45ZGW'
}

TagManager.initialize(tagManagerArgs)


function App() {
  return (
    <I18nextProvider i18n={i18next}>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/features" element={<Features />} />
        <Route path="/process" element={<Process />} />
        <Route path="/cookies-privacy-policy" element={<CookiesPage />} />
        <Route path="/data-request" element={<PrivacyPolicy />} />
        <Route path="/frequently-asked-question" element={<FrequentlyAskedQuestionSection />} />
        <Route path="/success" element={<SuccessView />} />
        {/* <Route path="/packages" element={<PricingComparisonPage />} /> */}
        <Route path="/softalmology-demo-booking" element={<BookingFrame />} />
        <Route path="/doctor/:id" element={<DoctorProfile />} />
        {/* ruta por defecto */}
        <Route path="*" element={<ErrorPage></ErrorPage>} />
      </Routes>
    </BrowserRouter>
    </I18nextProvider>

  );
}

export default App;