import React, { useState } from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import FooterSection from '../components/FooterSection';
import SuccessSection from '../components/SucessSection';

function SuccessView() {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
      setIsOpen(!isOpen)
    }
return <>

  <Sidebar isOpen={isOpen} toggle={toggle} />
  <Navbar toggle={toggle} />
  <SuccessSection/>
  <FooterSection />
  </>
  
}

export default SuccessView