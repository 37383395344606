import React, { useState } from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import {  Trans } from "react-i18next";

import {
  FAQContainer,
  FAQTitle,
  FAQH2,
  FAQH3,
  FAQItem,
  FAQuestions,
  FAQText,
} from "../components/FAQSection/FAQElements";
function FrequentlyAskedQuestionSection() {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
      setIsOpen(!isOpen)
    }
    return (
      <>
     <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      
      <FAQContainer>
        <FAQTitle>
          <Trans i18nKey="faqTitle"></Trans>
        </FAQTitle>
        <FAQH2>
          <Trans i18nKey="faqSubtitle"></Trans>
        </FAQH2>
        <FAQuestions>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion1"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer1"></Trans>
              <br/><br/>
             
            </FAQText>
          </FAQItem>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion2"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer2"></Trans>
            </FAQText>
          </FAQItem>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion3"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer3"></Trans>
            </FAQText>
          </FAQItem>

          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion4"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer4"></Trans>
            </FAQText>
          </FAQItem>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion5"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer5"></Trans>
            </FAQText>
          </FAQItem>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion6"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer6"></Trans>
            </FAQText>
          </FAQItem>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion7"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer7"></Trans>
            </FAQText>
          </FAQItem>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion8"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer8"></Trans>
            </FAQText>
          </FAQItem>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion9"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer9"></Trans>
            </FAQText>
          </FAQItem>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion10"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer10"></Trans>
            </FAQText>
          </FAQItem>
        </FAQuestions>
        
      </FAQContainer>
      </>
    )
}

export default FrequentlyAskedQuestionSection