import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { softalmologyDb } from "../firebase";
import {
  MDBContainer,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import { collection, getDocs } from "firebase/firestore";
import { Trans } from "react-i18next";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { SvgIcon } from "@mui/material";

import check from "../images/true.svg";
import none from "../images/cancel.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getAnalytics, logEvent } from "firebase/analytics";
import getStripe from "../getStripe";
import { Button } from "../components/ButtonElement";

function PricingComparisonPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const getPackages = async () => {
      const data = await getDocs(collection(softalmologyDb, "packages"));
      setPackages(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getPackages();
  }, []);
  const analytics = getAnalytics();

  async function handleCheckout(priceID) {
    try {
      const stripe = await getStripe();
      const { error } = await stripe.redirectToCheckout({
        lineItems: [
          {
            price: priceID,
            quantity: 1,
          },
        ],
        mode: "subscription",
        successUrl: "https://softalmology.com/success",
        cancelUrl: "https://softalmology.com/",
      });
      console.warn(error.message);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <MDBContainer className="py-5 text-center">
        <MDBTable
          responsive
          striped
          className="text-successtable-border border-light"
        >
          <MDBTableHead className="border-light">
            <tr>
              <th scope="col"></th>

              {packages.map(
                (softalmologyPackage) =>
                  softalmologyPackage.showInPage && (
                    <th scope="col">{softalmologyPackage.name}</th>
                  )
              )}
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            <tr>
              <th scope="row">
                <Trans i18nKey="useraccounts"></Trans>
              </th>
              {packages.map(
                (softalmologyPackage) =>
                  softalmologyPackage.showInPage && (
                    <td>{softalmologyPackage.contents.users}</td>
                  )
              )}
            </tr>
            <tr>
              <th scope="row">
                {" "}
                <Trans i18nKey="appointmentsperday"></Trans>
              </th>
              {packages.map(
                (softalmologyPackage) =>
                  softalmologyPackage.showInPage && (
                    <td>
                      {softalmologyPackage.contents.appointments === -1 ? (
                        <SvgIcon
                          component={AllInclusiveIcon}
                          inheritViewBox
                          height={10}
                          color="success"
                        />
                      ) : (
                        softalmologyPackage.contents.appointments
                      )}{" "}
                    </td>
                  )
              )}
            </tr>
            <tr>
              <th scope="row">
                {" "}
                <Trans i18nKey="gbforpatientfiles"></Trans>
              </th>
              {packages.map(
                (softalmologyPackage) =>
                  softalmologyPackage.showInPage && (
                    <td>
                      {softalmologyPackage.contents.gbfiles === -1 ? (
                        <SvgIcon
                          component={AllInclusiveIcon}
                          inheritViewBox
                          height={10}
                          color="success"
                        />
                      ) : (
                        softalmologyPackage.contents.gbfiles
                      )}{" "}
                      GB
                    </td>
                  )
              )}
            </tr>
            <tr>
              <th scope="row">
                {" "}
                <Trans i18nKey="livechat"></Trans>
              </th>
              {packages.map(
                (softalmologyPackage) =>
                  softalmologyPackage.showInPage && (
                    <td>
                      <LazyLoadImage
                        src={
                          softalmologyPackage.contents.livechat ? check : none
                        }
                        width={25}
                        alt={
                          softalmologyPackage.contents.livechat
                            ? "This package enables live chat in Softalmology"
                            : "This package does not enable live chat in Softalmology"
                        }
                      />
                    </td>
                  )
              )}
            </tr>

            <tr>
              <th scope="row">
                <Trans i18nKey="googlegmail"></Trans>
              </th>
              {packages.map(
                (softalmologyPackage) =>
                  softalmologyPackage.showInPage && (
                    <td>
                      <LazyLoadImage
                        src={softalmologyPackage.contents.gmail ? check : none}
                        width={25}
                        alt={
                          softalmologyPackage.contents.gmail
                            ? "This package enables google gmail integration in Softalmology"
                            : "This package does not enable google gmail integration in Softalmology"
                        }
                      />
                    </td>
                  )
              )}
            </tr>
            <tr>
              <th scope="row">
                <Trans i18nKey="googlecalendar"></Trans>
              </th>
              {packages.map(
                (softalmologyPackage) =>
                  softalmologyPackage.showInPage && (
                    <td>
                      <LazyLoadImage
                        src={
                          softalmologyPackage.contents.calendar ? check : none
                        }
                        width={25}
                        alt={
                          softalmologyPackage.contents.calendar
                            ? "This package enables google calendar integration in Softalmology"
                            : "This package does not enable google calendar integration in Softalmology"
                        }
                      />
                    </td>
                  )
              )}
            </tr>
            <tr>
              <th scope="row">
                <Trans i18nKey="whatsappbot"></Trans>
              </th>
              {packages.map(
                (softalmologyPackage) =>
                  softalmologyPackage.showInPage && (
                    <td>
                      <LazyLoadImage
                        src={
                          softalmologyPackage.contents.whatsapp ? check : none
                        }
                        width={25}
                        alt={
                          softalmologyPackage.contents.whatsapp
                            ? "This package enables whatsapp bot integration in Softalmology"
                            : "This package does not enable whatsapp bot integration in Softalmology"
                        }
                      />
                    </td>
                  )
              )}
            </tr>
            <tr>
              <th scope="row">
                {" "}
                <Trans i18nKey="lifeTimeSupport"></Trans>
              </th>
              {packages.map(
                (softalmologyPackage) =>
                  softalmologyPackage.showInPage && (
                    <td>
                      <LazyLoadImage
                        src={
                          softalmologyPackage.contents.lifetime ? check : none
                        }
                        width={25}
                        alt={
                          softalmologyPackage.contents.lifetime
                            ? "This package enables support in Softalmology"
                            : "This package does not enable support in Softalmology"
                        }
                      />
                    </td>
                  )
              )}
            </tr>

            <tr>
              <td></td>
              {packages.map(
                (softalmologyPackage) =>
                  softalmologyPackage.showInPage && (
                    <td>
                      <strong>
                        <h6>${softalmologyPackage.price}</h6>
                      </strong>
                    </td>
                  )
              )}
            </tr>
            <tr>
              <th></th>
              {packages.map(
                (softalmologyPackage) =>
                  softalmologyPackage.showInPage && (
                    <td>
                      <Button
                        primary
                        onClick={async () => {
                          try {
                            logEvent(analytics, softalmologyPackage.name, {
                              id: softalmologyPackage.stripe_id,
                              item: "softalmology package",
                              description: [
                                softalmologyPackage.name,
                                softalmologyPackage.price,
                              ],
                              size: "large",
                            });
                          } catch (e) {
                            console.log(e);
                          }

                          handleCheckout(softalmologyPackage.stripe_id);
                        }}
                      >
                        <Trans i18nKey="getStartedButton"></Trans>
                      </Button>
                    </td>
                  )
              )}
            </tr>
          </MDBTableBody>
        </MDBTable>
      </MDBContainer>
    </>
  );
}

export default PricingComparisonPage;
