import styled from "styled-components";

export const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;

  margin-top: 5px;
  margin-bottom: 100px;
  height: auto;
  position: relative;
  z-index: 1;
`;

export const FAQWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 24px;
  height: auto;
`;

export const FAQTitle = styled.h1`
  font-size: 2.5rem;
  color: #010606;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const FAQH1 = styled.h1`
  font-size: 2.5rem;
  color: #010606;
  margin-bottom: 64px;
  text-align: start;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const FAQH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`;

export const FAQH3 = styled.h3`
    color: #00218C;
    text-align: start;
    max-width: 450px;
    font-size: 1.02rem;
    width: 100%;
    margin-bottom: 10px;
`;

export const FAQItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 24px;
    height: auto;
    padding: 30px 0;
`;

export const FAQText = styled.p`
    max-width: 400px;
    font-size: 1rem;
    text-align: justify;
`;


export const FAQuestions = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    justify-content: center;
    align-items: start;
    max-width: 1000px;
    margin: 0 auto;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;



