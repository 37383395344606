import React, {useState,useEffect} from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import { FeatureTitle } from '../components/FeaturesSection'
import FooterSection from '../components/FooterSection'

function Features() {
  useEffect(() => {
    document.body.scrollTo(0, 0);
}, []);
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
     <Sidebar isOpen = {isOpen} toggle = {toggle}/>
     <Navbar toggle = {toggle}/>
     <FeatureTitle/>
      <FooterSection/>
    </>
  )
}

export default Features