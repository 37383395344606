import React from "react";
import {  Trans, useTranslation } from "react-i18next";

import {
  FAQContainer,
  FAQTitle,
  FAQH2,
  FAQH3,
  FAQItem,
  FAQuestions,
  FAQText,
} from "./FAQElements";
import { getAnalytics, logEvent } from "firebase/analytics";

import { Button } from "../ButtonElement";
function FAQSection() {
  useTranslation();

  function saveAnalyticEvent(event, data) {
    try{
      const analytics = getAnalytics();
      logEvent(analytics, event, data);
    }catch(e){console.log(e)}
    
  }
  return (
    <>
      <FAQContainer>
        <FAQTitle>
          <Trans i18nKey="faqTitle"></Trans>
        </FAQTitle>
        <FAQH2>
          <Trans i18nKey="faqSubtitle"></Trans>
        </FAQH2>
        <FAQuestions>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion1"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer1"></Trans>
            </FAQText>
          </FAQItem>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion2"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer2"></Trans>
            </FAQText>
          </FAQItem>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion3"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer3"></Trans>
            </FAQText>
          </FAQItem>

          {/* <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion4"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer4"></Trans>
            </FAQText>
          </FAQItem>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion5"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer5"></Trans>
            </FAQText>
          </FAQItem>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion6"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer6"></Trans>
            </FAQText>
          </FAQItem>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion7"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer7"></Trans>
            </FAQText>
          </FAQItem>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion8"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer8"></Trans>
            </FAQText>
          </FAQItem>
          <FAQItem>
            <FAQH3>
              <Trans i18nKey="faqQuestion9"></Trans>
            </FAQH3>
            <FAQText>
              <Trans i18nKey="faqAnswer9"></Trans>
            </FAQText>
          </FAQItem> */}
        </FAQuestions>
        <Button to="/frequently-asked-question" onClick={() => saveAnalyticEvent('faq_button_clicked', {name: 'faq_button_clicked'})}
        ><Trans i18nKey="faqTitle"></Trans></Button>
        
      </FAQContainer>
    </>
  );
}

export default FAQSection;
