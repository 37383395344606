import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

console.log("Hola");
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallBackLng: "en",
    resources: {
      en: {
        translation: {
          monthly: "Monthly",
          yearly: "Yearly",
          loadingSplash: "Loading your profile...",
          poweredBy: "Powered by",
          headertitle: "Manage your eye clinic with Softalmology",
          headerHomeButton: "Home",
          headerServicesButton: "Services",
          headerPricingButton: "Pricing",
          headerContactButton: "Contact us",
          headersubtitle:
            "Comfortable, safe and easy to use thanks to the cloud",

          featuresTab: "Features",
          featuresTitle: "Manage your clinic from anywhere",
          featuresSubtitle:
            "Growing clinics have an online administrative system, we improve the perfomance of your agenda, consultations, documents, clinical history, and more...",
          featuresButtonEasyToUse: "Easy to use",
          featuresButtonFastSupport: "Fast support",
          featuresButtonDailyAppointments: "Daily appointments",
          featuresButtonEMR: "Electronic Medical Records",
          featuresMoreDescription:
            "Softalmology's main objective is to help you with repetitive and tedious tasks, this by using the best technology on the market, all this to reduce the workload of ophthalmologists and improve the patient experience.",
          featuresEasyToUseSubtitle:
            "Softalmology will accompany you with an intuitive and easy-to-use design so you can focus on what matters most: your visual health.",
          featuresEasyToUseDescription:
            "The application is designed so that you can access your appointments, medical history and more anytime and anywhere. In addition, you will be accompanied by help and support sections so that you can answer any questions you may have. You can download the application on your mobile device 📱 or access through your web browser 💻.",
          featuresFastSupportSubtitle:
            "We have a dedicated support team to help you with any questions you may have.",
          featuresFastSupportDescription:
            "The application has support and help features so you can resolve any questions you may have. In addition, you can contact us through our email or social networks. We will be happy to help you with whatever you need ✨.",
          featuresDailyAppointmentsSubtitle:
            "Don't limit yourself, use Softalmology whenever you want!",
          featuresDailyAppointmentsDescription:
            "We have an active service 24 hours a day, 7 days a week. You can schedule your appointments at any time and place. In addition, you can access your medical history and more at any time and place ⌚.",
          featuresEMRSubtitle: "Never lose your medical records again!",
          featuresEMRDescription:
            "Softalmology has a digital medical record that will allow you to access your patients' medical data at any time and place. In addition, you can share your patients' data if you wish. And of course, all automated for minimize time and paper. 📝",

          aboutTab: "About",
          aboutTitle: "We want to help with repetitive and tedious tasks",
          aboutSubtitle:
            "Passionate about programming and automation, Softalmology will offer you help with your schedule and waiting list",
          aboutFirstPoint: "We will guide you in the process",
          aboutSecondPoint: "You will increase your productivity",
          aboutThirdPoint:
            "We will listen to your feedback and we will have constant updates",

          processTab: "Process",
          processTitle: "How we work?",
          processSubtitleMain: "One work. Efficiency.",
          processFirstPointTitle: "Ready to use",
          processFirstPointSubTitle:
            "When you complete your registration, you can use Softalmology",
          processSecondPointTitle: "Frequent Updates",
          processSecondPointSubTitle:
            "We will listen to your feedback and we will have constant updates",
          processThirdPointTitle: "Cloud-Based",
          processThirdPointSubTitle:
            "You can access from anywhere and at any time",
          processFourthPointTitle: "Google Services",
          processFourthPointSubTitle:
            "We use google services to offer a variety of features, such as google calendar",
          processSubtitle:
            "Softalmology's main objective is to help you with repetitive and tedious tasks, using the best technology on the market, all this to reduce the workload of ophthalmologists and improve the patient experience.",
          processReadyToUseSubtitle:
            "Whatever takes the longest is automated so you can use it as soon as you can. It's pretty fast!",
          processReadyToUseDescription:
            "To include softalmology in your clinic, all you have to do is contract the best convenience plan and in a matter of minutes you will receive an email with the instructions to access the program. In addition, you will be accompanied by a team specialized in providing support and sawdust.",
          processFrequentUpdatesSubtitle:
            "The world is moving fast, so is technology. Softalmology is not far behind!",
          processFrequentUpdatesDescription:
            "Softalmology has a constant update plan, where a new update will be available every Sunday. Our social networks will be open to offer you the best comfort.",
          processCloudBasesSubtitle:
            "Your information and that of your patients will always be available so you can access these documents from anywhere in the world.",
          processCloudBasedDescription:
            "Softalmology is designed with a database system hosted in the cloud, this means that your data will be stored in such a way that you can access it at any time and anywhere. In addition, you will be accompanied of a cybersecurity system so that your data is not compromised.",
          processGoogleServicesSubtitle: "We adapt to trends and conveniences",
          processGoogleServicesDescription:
            "Softalmology offers you connections like Google Gmail or Google Calendar so you can optimize your workflow, as well as a set of data analytics to improve your finances and help you reduce waiting times for your patients.",
          screenshotsTab: "Screenshots",
          screenshotsTitle: "See how it works",
          screenshotsSubtitle:
            "The solution is currently offered in English and Spanish, with more languages coming soon.",

          pricingTab: "Pricing plan",
          pricingTitle: "Choose the best plan for your clinic",
          month: "Month",
          year: "Year",
          livechat: "Live chat with employees",
          googlegmail: "Google Gmail Integration",
          googlecalendar: "Google Calendar Integration",
          whatsappbot: "Whatsapp Bot Integration",
          lifeTimeSupport: "Lifetime Support",

          pricingFirstPlanTitle: "Basic plan",
          pricingFirstPlanFirstPoint: "3 user accounts",
          pricingFirstPlanSecondPoint: "20 appointments per day",
          pricingFirstPlanThirdPoint: "10 GB for patient files",

          pricingSecondPlanTitle: "Medium plan",
          pricingSecondPlanFirstPoint: "5 user accounts",
          pricingSecondPlanSecondPoint: "50 appointments per day",
          pricingSecondPlanThirdPoint: "70 GB for patient files",

          pricingThirdPlanTitle: "Recommended plan",
          pricingThirdPlanFirstPoint: "Unlimited user accounts",
          pricingThirdPlanSecondPoint: "Unlimited appointments per day",
          pricingThirdPlanThirdPoint: "150 GB for patient files",

          pricingInfo:
            "If you want any customization you can contact us to discuss more details.",
          pricingAllPlans: "All plans",

          learnMoreButton: "Learn more",
          getStartedButton: "Get started!",
          learnMorePackagesButton: "Learn more about packages",

          downloadTab: "Download Softalmology",
          downloadTitle: "Available on the App Store and Google Play",
          downloadSubtitle:
            "The solution is currently offered in English and Spanish, with more languages coming soon.",

          contactUsTab: "Contact us",
          contactUsTitle: "We are here to help you",
          contactUsSubtitle:
            "We are here to help you. Please fill out the form and we will get back to you as soon as possible.",

          nameField: "Name",
          emailField: "Email",
          messageField: "Message",
          sendButton: "Send",

          footerExplore: "Explore",
          footerHome: "Home",
          footerFeatures: "Features",
          footerScreenshots: "Screenshots",
          footerServices: "Services",

          footerContact: "Send us a Newletters to get updates",
          footerEmail: "Enter your email",

          nameValidator: "Please enter your name",
          emailValidator: "Please enter your email",
          messageValidator: "Please enter your message",

          useraccounts: "User accounts",
          gbforpatientfiles: "GB for patient files",
          appointmentsperday: "Appointments per day",

          unlimitedtime: "For limited time only",
          login: "Login",
          selectLanguage: "Select language",
          betterExperience:
            "We work to offer you the best user experience and a beautiful experience during your stay at Softalmology.",
          quickSupport:
            "We are constantly reading the feedback from our customers to offer constant updates.",
          realTimeWord:
            "Softalmology is designed so that you can work with patients and clinic staff in real time",
          emrIntegration:
            "Softalmology offers you a file system through a customizable electronic medical record, you can add the requirements that your file must have and work with it",
          faqTitle: "Frequently Asked Questions",
          faqSubtitle:
            "Find the most frequently asked questions about Softalmology.",
          faqQuestion1: "How many users can be added within Softalmology?",
          faqAnswer1:
            "This will depend on the package you purchase, you will have access to at least 3 users, thinking about it as a doctor, receptionist and another user that you can add personally. However, if you want a larger number of users you can request a customization by contacting us.",
          faqQuestion2:
            "What will happen if I don't renew my subscription with Softalmology, will my records be deleted?",
          faqAnswer2:
            "No, your data will not be deleted, it will be stored in our database and we will offer you to export your patients and medical records and the total deletion of your data if you request it.",
          faqQuestion3: "What if I didn't like the program?",
          faqAnswer3:
            "We will offer you 7 days free to use Softalmology to familiarize yourself with the application and you can be sure to purchase our services; however, you can cancel your membership at any time and you will not be restricted from seeing the files you already generated with the program, only you will not be able to create new ones or schedule new patients.",
          faqQuestion4: "What happens if I run out of clinical file storage?",
          faqAnswer4:
            "Our system will detect this excess storage and we will send you a notification advising this event with possible escalation solutions.",
          faqQuestion5: "Can I change my subscription?",
          faqAnswer5:
            "Yes, at any time you can change your subscription without losing your data.",
          faqQuestion6: "How do I contact technical support?",
          faqAnswer6:
            "You will be provided with an email for technical support, you will also be able to communicate through our social networks Twitter, Instagram and Facebook.",
          faqQuestion7: "Can I customize my membership?",
          faqAnswer7:
            "Yes, Softalmology will offer you customizations if you wish. You can do it to our email.",
          faqQuestion8: "How often will updates be made?",
          faqAnswer8:
            "Updates will be made on Sunday nights, all our users will be notified in advance to take prior action.",
          faqQuestion9: "Can Softalmology be run from any device?",
          faqAnswer9:
            "Softalmology can be run from any device that has an AppStore, PlayStore and from a web browser",
          faqQuestion10: "What happens if my plan runs out of storage memory?",
          faqAnswer10:
            "Before your plan runs out of storage, we'll send you a warning email. If you don't want to be charged the additional storage fee, you can upgrade your plan before the storage runs out. If you don't upgrade your plan, you'll be charged the additional storage fee.",
          contactUsName: "Name",
          contactUsEmail: "Email",
          contactUsMessage: "Message",
          contactUsSend: "Send",
          allrightsreserved: "All rights reserved",
          developedby: "Developed by",
          privacyPolicy: "Privacy Policy",
          cookiesPolicy: "Cookies Policy",
          thanksForYourPurchase: "Thank you for your purchase",
          thanksForYourPurchaseSubtitle:
            "We have sent you an email with the information about your purchase",
          successNextStep: "What is the next step? 🤔",
          nextStep: "Next step",
          step1:
            "You will receive an email with the information about your purchase.",
          step2:
            "Inside the email, you will find the credentials to access Softalmology.",
          step3:
            "Go to the Softalmology page and log in using the credentials we have sent you.",
          step4:
            "Once logged in, you can change your password to a more secure one.",
          successAfterLogin1:
            "You can access your Softalmology account from any device.",
          successAfterLogin2:
            "Inside the portal, you will see buttons for help and getting started.",
          contactUs:
            "If you have any questions, you can contact us through our email.",
          noEmailReceived: "Did not receive any email? 👀",
          doctorProfileProfile: "PROFILE",
          doctorProfileSpecialty: "SPECIALTY",
          doctorProfileExperience: "EXPERIENCE",
          doctorProfileLanguages: "LANGUAGES",
          doctorProfileTypesOf: "TYPES OF",
          doctorProfileContact: "CONTACT",
          doctorProfileAddress: "Address",
          doctorProfilePhone: "Phone",
          doctorProfileEmail: "Email",
          doctorProfileBiography: "BIOGRAPHY",
          billedYearly: "Billed yearly",
          bookDemo: "Book a demo",
        },
      },
      es: {
        translation: {
          monthly: "Mensual",
          yearly: "Anual",
          loadingSplash: "Cargando tu perfil...",
          poweredBy: "Desarrollado por",
          headertitle: "Maneja tu centro oftalmológico con Softalmology",
          headerHomeButton: "Inicio",
          headerServicesButton: "Servicios",
          headerPricingButton: "Precios",
          headerContactButton: "Contactanos",
          headersubtitle: "Confortable, fácil y seguro gracias a la nube",

          featuresTab: "Características",
          featuresTitle: "Maneja tu clínica desde cualquier lugar",
          featuresSubtitle:
            "Las clínicas en crecimiento cuentan con un sistema administrativo en línea, mejoramos el rendimiento de tu agenda, consultas, documentos, historial clínico, y más...",
          featuresButtonEasyToUse: "Fácil de usar",
          featuresButtonFastSupport: "Soporte rápido",
          featuresButtonDailyAppointments: "Citas diarias",
          featuresButtonEMR: "Historial clínico",
          featuresMoreDescription:
            "El principal objetivo de Softalmology es ayudarte con las tareas repetitivas y tediosas, esto mediante el uso de la mejor técnología del mercado, todo esto para reducir la carga de trabajo de los oftalmólogos y mejorar la experiencia de los pacientes.",
          featuresEasyToUseSubtitle:
            "Softalmology te acompañará con un diseño intuitivo y fácil de usar para que puedas concentrarte en lo que más importa: tu salud visual.",
          featuresEasyToUseDescription:
            "La aplicación está diseñada para que puedas acceder a tus citas, historial médico y más en cualquier momento y lugar. Además, estarás acompañado por secciones de ayuda y soporte para que puedas resolver cualquier duda que tengas. Puedes descargar la aplicación en tu dispositivo móvil 📱 o acceder a través de tu navegador web 💻.",
          featuresFastSupportSubtitle:
            "Tenemos un equipo de soporte dedicado para ayudarte con cualquier duda que tengas.",
          featuresFastSupportDescription:
            "La aplicación cuenta con características de soporte y ayuda para que puedas resolver cualquier duda que tengas. Además, puedes contactarnos a través de nuestro correo electrónico o redes sociales. Estaremos encantados de ayudarte en lo que necesites ✨.",
          featuresDailyAppointmentsSubtitle:
            "¡No te límites, usa Softalmology cuando lo deseés!",
          featuresDailyAppointmentsDescription:
            "Contamos con un servicio activo las 24 horas del día, los 7 días de la semana. Puedes agendar tus citas en cualquier momento y lugar. Además, puedes acceder a tu historial clínico y más en cualquier momento y lugar ⌚.",
          featuresEMRSubtitle: "¡No vuelvas a perder tu historial clínico!",
          featuresEMRDescription:
            "Softalmology cuenta con un historial clínico digital que te permitirá acceder a los datos médicos de tus pacientes en cualquier momento y lugar. Además, podrás compartir los datos de tus pacientes si lo deseas. Y por su puesto, todo automatizado para minimizar el tiempo y el papel. 📝",

          aboutTab: "Acerca de",
          aboutTitle:
            "Te queremos ayudar con las necesidades de tu clínica, tareas repetitivas y tediosas",
          aboutSubtitle:
            "Apasionados con la automatización, Softalmology te ayudará con tu agenda y lista de espera",
          aboutFirstPoint:
            "Te guiaremos en el proceso de automatización de tu clínica",
          aboutSecondPoint: "Incrementarás la productividad de tu clínica",
          aboutThirdPoint:
            "Escucharemos tu retroalimentación para mejorar y lanzar actualizaciones constantemente",

          processTab: "Proceso",
          processTitle: "¿Cómo funciona Softalmology?",
          processSubtitleMain: "Una palabra. Eficiencia.",
          processFirstPointTitle: "Listo para usar",
          processFirstPointSubTitle:
            "No necesitas instalar nada, solo necesitas una conexión a internet",
          processSecondPointTitle: "Actualizaciones constantes",
          processSecondPointSubTitle:
            "Nuestro equipo de desarrollo está trabajando constantemente para mejorar tu experiencia",
          processThirdPointTitle: "Basado en la nube",
          processThirdPointSubTitle:
            "Tus datos están seguros y disponibles desde cualquier lugar que tengas acceso a internet",
          processFourthPointTitle: "Google Services",
          processFourthPointSubTitle:
            "Utilizamos servicios de google para mejorar tu experiencia, como Google Calendar",
          processSubtitle:
            "El principal objetivo de Softalmology es ayudarte con las tareas repetitivas y tediosas, esto mediante el uso de la mejor técnología del mercado, todo esto para reducir la carga de trabajo de los oftalmólogos y mejorar la experiencia de los pacientes.",
          processReadyToUseSubtitle:
            "Lo que toma más tiempo está automatizado para que lo puedas usar en cuanto puedas. ¡Es bastante rápido!",
          processReadyToUseDescription:
            "Para incluir softalmology en tu clinica, basta con contratar el mejor plan de conveniencia y en cuestión de minutos te llegará un correo con las indicaciones para poder acceder al programa. Además de ello estarás acompañado de un equipo especializado en brindarle apoyo y aseroría.",
          processFrequentUpdatesSubtitle:
            "El mundo avanza rápidamente, la tecnología también. ¡Softalmology no se queda atrás!",
          processFrequentUpdatesDescription:
            "Softalmology cuenta con un plan de actualizaciones constantes, donde cada domingo estará presente una nueva actualización. Nuestras redes sociales estarán abiertas para ofrecerte la mejor comodidad.",
          processCloudBasesSubtitle:
            "Tu información y la de tus pacientes estarán siempre disponibles para que puedas acceder a estos documentos desde cualquier parte del mundo.",
          processCloudBasedDescription:
            "Softalmology está diseñado con un sistema de base de datos alojado en la nube, esto quiere decir que tus datos estarán almacenados de tal forma que puedas acceder a ellos en cualquier hora y en cualquier lugar. Además de ello, estarás acompañado de un sistema de ciberseguridad para que tus datos no se vean comprometidos.",
          processGoogleServicesSubtitle:
            "Nos adaptamos a las tendencias y las comodidades",
          processGoogleServicesDescription:
            "Softalmology te ofrece conexiones como Google Gmail o Google Calendar para que puedas optimizar tu flujo de trabajo, además de ello un conjunto de analisis de datos para mejorar tus finanzas y ayudarte a reducir los tiempos de espera de tus pacientes.",

          screenshotsTab: "Capturas de pantalla",
          screenshotsTitle: "Mira cómo funciona",
          screenshotsSubtitle:
            "La aplicación se muestra en español e inglés y pronto estará disponible en otros idiomas",

          pricingTab: "Planes y precios",
          pricingTitle: "Elige el plan que mejor se adapte a tus necesidades",
          month: "Mes",
          year: "Año",
          livechat: "Chat con los trabajadores",
          googlegmail: "Integración con Google Gmail",
          googlecalendar: "Integración con Google Calendar ",
          whatsappbot: "Integración con Whatsapp Bot ",
          lifeTimeSupport: "Soporte de por vida",

          pricingFirstPlanTitle: "Plan básico",
          pricingFirstPlanFirstPoint: "3 cuentas de usuario",
          pricingFirstPlanSecondPoint: "20 citas por día",
          pricingFirstPlanThirdPoint: "10 GB para archivos y documentos",

          pricingSecondPlanTitle: "Plan medio",
          pricingSecondPlanFirstPoint: "5 cuentas de usuario",
          pricingSecondPlanSecondPoint: "50 citas por día",
          pricingSecondPlanThirdPoint: "70 GB para archivos y documentos",

          pricingThirdPlanTitle: "Plan avanzado",
          pricingThirdPlanFirstPoint: "Cuentas de usuario ilimitadas",
          pricingThirdPlanSecondPoint: "Citas por día ilimitadas",
          pricingThirdPlanThirdPoint: "150 GB para archivos y documentos",

          pricingInfo:
            "Si necesitas más información o algúna personalización, por favor contáctanos",
          pricingAllPlans: "Todos los paquetes",

          learnMoreButton: "Leer más",
          getStartedButton: "Empezar",
          learnMorePackagesButton: "Leer más sobre los paquetes",

          downloadTab: "Descarga Softalmology",
          downloadTitle: "Disponible en App Store y Google Play",
          downloadSubtitle:
            "La aplicación se muestra en español e inglés y pronto estará disponible en otros idiomas",

          contactUsTab: "Contáctanos",
          contactUsTitle: "¿Tienes alguna pregunta o comentario?",
          contactUsSubtitle:
            "Estamos aquí para ayudarte, por favor rellena el formulario y nos pondremos en contacto contigo lo antes posible",

          nameField: "Nombre",
          emailField: "Correo electrónico",
          messageField: "Mensaje",
          sendButton: "Enviar",

          footerExplore: "Explorar",
          footerHome: "Inicio",
          footerFeatures: "Características",
          footerScreenshots: "Capturas de pantalla",
          footerServices: "Servicios",

          footerContact: "¿Deseas recibir actualizaciones constantes?",
          footerEmail: "Correo electrónico",

          nameValidator: "Por favor ingresa tu nombre",
          emailValidator: "Por favor ingresa un correo electrónico válido",
          messageValidator: "Por favor ingresa un mensaje",

          useraccounts: "Usuarios",
          gbforpatientfiles: "GB para archivos clinicos",
          appointmentsperday: "Citas por día",
          unlimitedtime: "Por tiempo ilimitado",

          login: "Iniciar sesión",
          selectLanguage: "Selecciona el idioma",
          betterExperience:
            "Trabajamos para ofrecerte la mejor experiencia de usuario y una bonita experiencia en tu estadía en Softalmology.",
          quickSupport:
            "Estamos en constante lectura de la retroalimentación de nuestros clientes para ofrecer actualizaciones constantes.",
          realTimeWord:
            "Softalmology está diseñado para que puedas trabajar con los pacientes, y personal de la clínica en tiempo real",
          emrIntegration:
            "Softalmology te ofrece un sistema de expedientes mediante un historial clínico electrónico personalizable, podrás añadir los requisitos que debe de tener tu expediente y trabajar con ello.",
          faqTitle: "Preguntas frecuentes",
          faqSubtitle:
            "Encuentra las preguntas más frecuentes acerca de Softalmology.",
          faqQuestion1:
            "¿Cuántas personas pueden estar agregadas dentro de Softalmology?",
          faqAnswer1:
            "Esto dependerá del paquete que adquieras, minimo tendrás acceso a 3 usuarios, pensandolo como doctor, recepcionista y otro usuario que podrás agregar personalizadamente. Sin embargo, si deseas una mayor cantidad de usuarios puedes solicitar una personalizacion contactandote con nosotros.",
          faqQuestion2:
            "¿Qué pasará si no renuevo mi subscripción con Softalmology, mis expedientes serán eliminados?",
          faqAnswer2:
            "No, tus datos no serán eliminados, estarán almacenados en nuestra base de datos y te ofreceremos exportar tus pacientes y expedientes clínicos y la eliminación total de tus datos en caso de que lo solicites.",
          faqQuestion3: "¿Qué pasa si no me gustó el programa?",
          faqAnswer3:
            "Te ofreceremos 7 días gratuitos para utilizar Softalmology para familiarizarte con la aplicación y puedas estar seguro de aquirir nuestros servicios; ssin embargo, puedes cancelar tu membresía en cualquier momento y no estarás restringido de ver los expedientes que ya generasste con el programa, solamente no podrás crear nuevos ni agendar nuevos pacientes.",
          faqQuestion4:
            "¿Qué pasa si me quedo sin almacenamiento de archivos clinicos?",
          faqAnswer4:
            "Nuestro sistema detectará este excedente de almacenmianto y te enviaremos una notificación avisando dicho avento con posibles soluciones de escalamiento.",
          faqQuestion5: "¿Puedo cambiar mi subscripción?",
          faqAnswer5:
            "Si, en todo momento podrás cambiar tu subscripción sin perder tus datos.",
          faqQuestion6: "¿Cómo me contacto con soporte técnico?",
          faqAnswer6:
            "Se te brindará un correo electrónico para soporte técnico, además podrás comunicarte por nuestras redes sociales Twitter, Instagram y Facebook.",
          faqQuestion7: "¿Puedo personalizar mi membresía?",
          faqAnswer7:
            "Si, Softalmology te ofrecerá personalizaciones en caso de que lo deseés. ¡Puedes hacerlo a nuestro correo electrónico.",
          faqQuestion8: "¿Cada cuanto se harán actualizaciones?",
          faqAnswer8:
            "Las actualizaciones se harán los domingos por la noche, se notificará previamente a todos nuestros usuarios para que tomen medidas previas.",
          faqQuestion9:
            "¿Softalmology puede ejecutarse desde cualquier dispositivo?",
          faqAnswer9:
            "Softalmology puede ejecutarse desde cualquier dispositivo que tenga AppStore, PlayStore y desde navegador Web",
          faqQuestion10:
            " ¿Qué pasa si se acaba la memoria de almacenamiento de mi plan?",
          faqAnswer10:
            "Antes de que se acabe la memoria de almacenamiento de tu plan, te enviaremos un correo electrónico de aviso. Si no quieres que se te cobre el cargo adicional por almacenamiento, puedes actualizar tu plan antes de que se acabe la memoria de almacenamiento. Si no actualizas tu plan, se te cobrará el cargo adicional por almacenamiento.",
          contactUsName: "Nombre",
          contactUsEmail: "Correo electrónico",
          contactUsMessage: "Mensaje",
          contactUsSend: "Enviar",
          allrightsreserved: "Todos los derechos reservados",
          developedby: "Desarrollado por",
          privacyPolicy: "Política de privacidad",
          cookiesPolicy: "Política de cookies",
          thanksForYourPurchase: "Gracias por tu compra",
          thanksForYourPurchaseSubtitle:
            "Te hemos enviado un correo con la información de tu compra",
          successNextStep: "¿Cuál es el siguiente paso? 🤔",
          step1: "Recibirás un correo con la información de tu compra.",
          step2:
            "Dentro del correo encontrarás las credenciales de acceso a Softalmology.",
          step3:
            "Ingresa a la página de Softalmology y accede con las credenciales que te hemos enviado.",
          step4:
            "Al iniciar sesión, podrás cambiar tu contraseña por una más segura.",
          nextStep: "¿Y ahora qué? 🤔",
          successAfterLogin1:
            "Podrás acceder a tu cuenta de Softalmology desde cualquier dispositivo.",
          successAfterLogin2:
            "Dentro del portal, se te mostrarán botones para ayuda y primeros pasos.",
          contactUs:
            "Si tienes alguna duda, puedes contactar con nosotros a través de nuestro correo electrónico.",
          noEmailReceived: "¿No recibiste ningún correo electrónico? 👀",
          doctorProfileProfile: "PERFIL",
          doctorProfileSpecialty: "ESPECIALIDAD",
          doctorProfileExperience: "EXPERIENCIA",
          doctorProfileLanguages: "IDIOMAS",
          doctorProfileTypesOf: "TIPOS DE",
          doctorProfileContact: "CONTACTO",
          doctorProfileAddress: "Dirección",
          doctorProfilePhone: "Teléfono",
          doctorProfileEmail: "Correo electrónico",
          doctorProfileBiography: "BIOGRAFÍA",
          billedYearly: "Facturado anualmente",
          bookDemo: "Agendar demo"
        },
      },
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n;
