import styled from 'styled-components';

export const ProcessContainer = styled.div`
    color: #fff;
    margin-top: 0px;
    padding-top: 0px;
    background: ${({lightBg}) => (!lightBg ? 'white' : '#010606')};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 100px;
    flex-direction: column;
    align-content: space-between;

    @media screen and (max-width: 1250px) {
        padding: 100px 0;
        // display 1 row and 1 column
        flex-direction: column;

    }

    @media screen and (max-width: 480px) {
        padding: 100px 0;
        // display 1 row and 1 column
        flex-direction: column;
    }
`

export const ProcessTitle = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: #010606;

    @media screen and (max-width: 480px) {
        font-size: 1.5rem;
    }
`

export const ProcessSubtitle = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 20px;
    color: #010606;

    @media screen and (max-width: 480px) {
        font-size: 1.5rem;
    }
`

export const ProcessDescription = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
    font-size: 1.3rem;
    font-weight: 400;
    font-weight: 400;
    text-align: center;
    margin-top: 0px;
    max-width: 1200px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: #010606;

    @media screen and (max-width: 480px) {
        font-size: 1.5rem;
    }
`


export const GridCards = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-template-areas: 'col1 col2 col3 col4';
    justify-content: center;
    width: 100%;

    grid-gap: 20px;
    padding: 0 2rem;



`


export const ProcessCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: row;
    gap: 20px;
    height:auto;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    padding: 30px;
    transition: all 0.2s ease-in-out;
    color: #010606;
    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
  

    // if screen is less than 1400 px display 2 rows and 2 columns
    @media screen and (max-width: 1400px) {
        display:grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }


    @media screen and (max-width: 768px) {
        display:flex;
        flex-direction: column;
    }

    @media screen and (max-width: 480px) {
        display:flex;
        flex-direction: column;
    }

`