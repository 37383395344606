import React, {useEffect} from "react";
import {
  CenteredDiv,
  Img,
  NextStepContainer,
  NextStepDiv,
  NextStepText,
  NextStepTitle,
  SuccessContainer,
  Text,
  ThanksCard,
  Title,
  Video,
} from "./SucessElements";
import Icon2 from "../../images/done.png";
import video from "../../images/dashboard.mp4";

import { Button3 } from "../ButtonElement";
import { NavBtn } from "../Navbar/NavbarElements";
import { Trans, useTranslation } from "react-i18next";
import ContactSection from "../ContactSection";
import { getAnalytics, logEvent } from "firebase/analytics";

function SuccessSection() {
  useTranslation();


  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, "page_view", {
      page_title: "Success",  
    });
  }, []);
  return (
    <>
      <SuccessContainer>
        <ThanksCard>
          <Img src={Icon2} alt="icon1" />
          <Title>
            <Trans i18nKey="thanksForYourPurchase"></Trans>
          </Title>
          <Text>
            <Trans i18nKey="thanksForYourPurchaseSubtitle"></Trans>
          </Text>
        </ThanksCard>

        <NextStepDiv>
          <NextStepContainer>
            <div>
              <NextStepTitle>
                <Trans i18nKey="successNextStep"></Trans>
              </NextStepTitle>
              <NextStepText>
                <p>
                  <Trans i18nKey="step1"></Trans>
                </p>
                <p>
                  <Trans i18nKey="step2"></Trans>
                </p>
                <p>
                  <Trans i18nKey="step3"></Trans>
                </p>
                <p>
                  <Trans i18nKey="step4"></Trans>
                </p>
              </NextStepText>
              <NextStepTitle>
                <Trans i18nKey="nextStep"></Trans>
              </NextStepTitle>
              <NextStepText>
                <p>
                  <Trans i18nKey="successAfterLogin1"></Trans>
                </p>
                <p>
                  <Trans i18nKey="successAfterLogin2"></Trans>
                </p>
                <br />
                <p>
                  <Trans i18nKey="contactUs"></Trans>
                </p>
              </NextStepText>
              <a
                href="https://my.softalmology.com/"
                target="_blank" 
                rel="noopener noreferrer"
                style={{ textDecorationLine: "none" }}
              >
                <Button3
                  secondary
                  href="https://my.softalmology.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans i18nKey="login"></Trans>
                </Button3>
              </a>
              <br />
            </div>
            <Video src={video} autoPlay loop muted />
            <NavBtn></NavBtn>
          </NextStepContainer>
        </NextStepDiv>
      </SuccessContainer>
      <CenteredDiv>
        <NextStepTitle>
          {" "}
          <Trans i18nKey="noEmailReceived"></Trans>
        </NextStepTitle>
        <ContactSection />
      </CenteredDiv>
    </>
  );
}

export default SuccessSection;
