import React, {useState} from 'react';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import FooterSection from '../FooterSection';
function BookingFrame() {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
      setIsOpen(!isOpen)
    }
  return (
    <>
     <Sidebar isOpen = {isOpen} toggle = {toggle}/>
     <Navbar toggle = {toggle}/>
    <iframe
      src="https://meetings.hubspot.com/hector-beltran/softalmology-demo-booking"
      width="100%"
      height="800px"
      title="Softalmology Demo Booking"

    />
          <FooterSection/>

    </>
  );
}

export default BookingFrame;