import styled from "styled-components";

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 0 30px;
  height: auto;
  position: relative;
  z-index: 1;
`;

export const ContactLottie = styled.div`
  width: 30%;
  height: 100%;
`;

export const ContactWrapper = styled.div`
    display: flex;
    z-index: 1;
    height: auto;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    justify-content: space-around;
    align-items: start;

    @media screen and (max-width: 1250px) {
        padding: 0 24px;
        flex-direction: column;
    }
`;

export const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 100%;
    margin: 0 30px;
    background: #fff;
    border-radius: 10px;

    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 0;
    }
`;

export const ContactInput = styled.input`
    width: 100%;
    height: 50px;
    margin: 10px 0;
    padding: 0 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    margin:10px;
    transition: all 0.3s ease-in-out;

    &:focus {
        border: 1px solid #000;
    }
`;

export const ContactTextArea = styled.textarea`
    width: 100%;
    height: 100px;

    margin: 10px;
    padding: 0 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    transition: all 0.3s ease-in-out;

    &:focus {
        border: 1px solid #000;
    }
`;

export const ContactButton = styled.button`
    width: 100%;
    height: 50px;
    margin: 10px 0;
    padding: 0 20px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    background: #000;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        background: #fff;
        color: #000;
    }
`;

export const ContactH1 = styled.h1`
    color: #000;
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
    
`;

export const ContactP = styled.p`
    margin-top: 10px;
    color: #000;
    font-size: 18px;
    text-align: center;
    text-align: justify;

`;

export const ContactRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 30px;
    background: #fff;
    border-radius: 10px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

export const ContactColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    height: 100%;
    padding: 0 30px;
    margin: 0 30px;
    background: #fff;
    border-radius: 10px;

    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 0;
    }

    @media screen and (max-width: 480px) {
        padding: 0 0px;

    }

`;


