import styled from "styled-components";

export const DoctorFlex = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  margin: 10px;
  align-items: ${({ centered }) => (centered ? "center" : "flex-start")};
  justify-content: ${({ centered }) => (centered ? "center" : "flex-start")};
  
  @media screen and (max-width: 768px) {
    flex-direction: column;

  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }

 
  
`;

export const DoctorPresentation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 450px;
  margin-top: 20px;
  text-align: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
  }

  
`;

export const DoctorImage = styled.img`
  width: 100%;
  height: 600px;
  object-fit: fill;
`;

export const DoctorInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-left: 1px solid #000;
  background: rgb(140, 0, 255);
`;

export const DoctorName = styled.p`
  font-size: ${({ big }) => (big ? "1.5rem" : "1.2rem")};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  color: ${({ white }) => (white ? "#fff" : "#000")};
`;

export const DoctorSpecialty = styled.p`
  font-size: ${({ small }) => (small ? "1rem" : "1.3rem")};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  color: ${({ white, gray }) => (white ? "#fff" : gray ? "#8C8989" : "#000")};
`;

export const ProfileTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  margin: ${({ margin }) => (margin ? "10px" : "0")};
  text-align: start;
  color: rgb(140, 0, 255);
`;

export const BoldText = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  color: #0e0e0e;
`;

export const DoctorData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 10px;
  margin-left: 40px;
  margin-bottom: 20px;
  text-align: start;
  background: #fff;

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

export const DoctorLi = styled.li`
  margin-top: 10px;
  font-size: 0.8rem;
  font-weight: 400;
  color: #646363;
  margin: 2px;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const DoctorUl = styled.ul`
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;


export const SpecialtiesBg = styled.div`
  background: #FFFFFF;
  margin-left: -10px;
  width: 100%;
  padding-top: 10px;

  @media screen and (max-width: 768px) {
    padding-left: 0;
  }
`;

export const DoctorSpecialties = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
gap: 10px;
margin: 10px;

& > div {
  flex: 1 0 calc(25% - 10px);

  @media screen and (max-width: 768px) {
    flex-basis: calc(33.33% - 10px);
  }

  @media screen and (max-width: 480px) {
    flex-basis: 100%;
  }
}

`;

export const Specialty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 5px;
  border: 1px solid #a8a7a76e;
  border-radius: 10px;
  padding: 1px;
  text-align: center;
  font-size: 0.8rem;
`;

export const DoctorCV = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  text-align: center;
  font-size: 0.8rem;
`;

export const DoctorCVLi = styled.li`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 10px;
  width: 300px;
  font-size: 0.8rem;
  color: #646363;
  margin: 2px;
`;

export const DoctorTitleDiv = styled.div`
  width: 120px;
`;
export const DoctorParagraph = styled.p`
  font-size: 0.85rem;
  font-weight: 400;
  color: #646363;
  margin: 2px;
  text-align: start;
`;



export const DoctorBiography = styled.div`
    font-weight: 500;
    text-align: justify;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    margin:20px;
    color: #646363;

`;
export const DoctorBiographyP = styled.p`
    font-size: 0.85rem;
    font-weight: 400;
  
    color: #646363;
    margin: 2px;
    text-align: start;
    width: 100%;
    margin-left: 40px;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
    margin-left: 0;
    }
`;

export const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
