import React from "react";

import {
  ContactContainer,
  ContactWrapper,
  ContactButton,
  ContactTextArea,
  ContactRow,
  ContactP,
  ContactInput,
  ContactH1,
  ContactColumn,
} from "./ContactElements";
import { useTranslation, Trans } from "react-i18next";
import { getAnalytics, logEvent } from "firebase/analytics";

function ContactSection() {
  

  function sendMail() {
    var to = "admin@codingsolved.com";
    var subject = document.getElementById("name").value;
    var body = document.getElementById("message").value;
    window.open("mailto:" + to + "?subject=" + subject + "&body=" + body);
    saveAnalyticEvent("send_mail", { name: subject, message: body });
  }
  const { t } = useTranslation();
  function saveAnalyticEvent(event, data) {
    try{
      const analytics = getAnalytics();
      logEvent(analytics, event, data);
    }catch(e){console.log(e)}
    
  }
  return (
    <ContactContainer>
      <ContactWrapper>
        <ContactColumn>
          <ContactColumn>
            <ContactH1>
              <Trans i18nKey="contactUsTitle"></Trans>
            </ContactH1>
            <ContactP>
              <Trans i18nKey="contactUsSubtitle"></Trans>
            </ContactP>
          </ContactColumn>
          <ContactRow>
            <ContactInput
              type="text"
              placeholder={t("contactUsName")}
              id="name"
            />
          </ContactRow>
          <ContactRow>
            <ContactTextArea placeholder={t("contactUsMessage")} id="message" />
          </ContactRow>
          <ContactRow>
            <ContactButton onClick={() => sendMail()}>
              <Trans i18nKey="contactUsSend"></Trans>
            </ContactButton>
          </ContactRow>
        </ContactColumn>
      </ContactWrapper>
    </ContactContainer>
  );
}

export default ContactSection;
