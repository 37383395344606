 // Import the functions you need from the SDKs you need
 import { initializeApp } from "firebase/app";
 import { getFirestore } from "firebase/firestore";

 // TODO: Add SDKs for Firebase products that you want to use
 // https://firebase.google.com/docs/web/setup#available-libraries
 // Your web app's Firebase configuration
 const firebaseConfig = {
  apiKey: "AIzaSyDMIQylB4hlBBxxFVFR-dyC1H_nx7BZ0n0",
  authDomain: "softalmology-web.firebaseapp.com",
  projectId: "softalmology-web",
  storageBucket: "softalmology-web.appspot.com",
  messagingSenderId: "778695843977",
  appId: "1:778695843977:web:f24b23795386b9a21aa658",
  measurementId: "G-WCDHX7DRE4",
};

const softalmologyConfig = {
  apiKey: "AIzaSyBDsTk4_l5smcOPiIiFVWpXpMxKv8i9p1Q",
  authDomain: "centro-oftalmologico-de-sonora.firebaseapp.com",
  databaseURL: "https://centro-oftalmologico-de-sonora-default-rtdb.firebaseio.com",
  projectId: "centro-oftalmologico-de-sonora",
  storageBucket: "centro-oftalmologico-de-sonora.appspot.com",
  messagingSenderId: "632721949075",
  appId: "1:632721949075:web:a075c623d6a40a4b40a866",
  measurementId: "G-DCP2MMTMHT"
};

 // Initialize Firebase
 
 const app = initializeApp(firebaseConfig);
 const softalmologyApp = initializeApp(softalmologyConfig, "softalmologyApp");
 // Export firestore database 
 // It will be imported into your react app whenever it is needed
 export const db = getFirestore(app);
 export const softalmologyDb = getFirestore(softalmologyApp);


 