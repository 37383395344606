import React from "react";

import {  Trans, useTranslation } from "react-i18next";

import {
  BoldText,
  DoctorBiography,
  DoctorCV,
  DoctorCVLi,
  DoctorData,
  DoctorFlex,
  DoctorImage,
  DoctorInfo,
  DoctorLi,
  DoctorName,
  DoctorParagraph,
  DoctorPresentation,
  DoctorSpecialties,
  DoctorSpecialty,
  DoctorUl,
  ProfileTitle,
  SpecialtiesBg,
  Specialty,
} from "./DoctorProfileElements";

function DoctorInfoCard(props) {
  useTranslation();
  const {
    firstname,
    lastname,
    speciality,
    university,
    degree,
    address,
    specialties,
    experience,
    languages,
    studies,
    biography,
    officeAddress,
    cellphone,
    email,
    photoURL,
  } = props.doctor || {};


  return (
    <>
      <DoctorFlex column  centered>
        <DoctorFlex row  >
          <DoctorPresentation>
            {photoURL && (
              <DoctorImage
                src={photoURL}
                alt="doctor"
              />
            )}

            <DoctorInfo>
              <DoctorName bold white>
                Dr. {firstname} {lastname}
              </DoctorName>
              <DoctorSpecialty white>{speciality}</DoctorSpecialty>
            </DoctorInfo>
          </DoctorPresentation>
          <DoctorFlex column centered>
            <DoctorData>
              <ProfileTitle bold>
                <Trans i18nKey="doctorProfileProfile" />
              </ProfileTitle>
              <DoctorName bold big>
                Dr. {firstname} {lastname}
              </DoctorName>
              <DoctorSpecialty gray small>
                {speciality}
              </DoctorSpecialty>
              <DoctorUl>
                <DoctorLi>{university}</DoctorLi>
                <DoctorLi>{degree}</DoctorLi>
                <DoctorLi>{address}</DoctorLi>
              </DoctorUl>
              <SpecialtiesBg>
                <ProfileTitle margin bold>
                  <Trans i18nKey="doctorProfileSpecialty" />
                </ProfileTitle>
                <DoctorSpecialties>
                  {specialties &&
                    specialties.length > 0 &&
                    specialties.map((specialty, index) => (
                      <Specialty key={index}>{specialty}</Specialty>
                    ))}
                </DoctorSpecialties>
                <DoctorCV>
                  <DoctorCVLi>
                    <ProfileTitle margin bold>
                     <Trans i18nKey="doctorProfileExperience" />
                    </ProfileTitle>
                    <DoctorParagraph>{experience}</DoctorParagraph>
                  </DoctorCVLi>
                  <DoctorCVLi>
                    <ProfileTitle margin bold>
                     <Trans i18nKey="doctorProfileLanguages" />
                    </ProfileTitle>
                    {languages &&
                      languages.length > 0 &&
                      languages.map((language, index) => (
                        <DoctorParagraph key={index}>
                          {language}
                        </DoctorParagraph>
                      ))}
                  </DoctorCVLi>
                  <DoctorCVLi>
                    <ProfileTitle margin bold>
                      <Trans i18nKey="doctorProfileTypesOf" />
                    </ProfileTitle>
                    <DoctorParagraph>{studies}</DoctorParagraph>
                  </DoctorCVLi>
                  <DoctorFlex column>
            <ProfileTitle  bold>
             <Trans i18nKey="doctorProfileContact" />
            </ProfileTitle>
            <DoctorUl>
              <DoctorLi>
                <BoldText>
                  <Trans i18nKey="doctorProfileAddress" />:
                  </BoldText> {officeAddress}
              </DoctorLi>
              <DoctorLi>
                <BoldText>
                  <Trans i18nKey="doctorProfilePhone" />:
                  </BoldText> {cellphone}
              </DoctorLi>
              <DoctorLi>
                <BoldText>
                  <Trans i18nKey="doctorProfileEmail" />:
                  </BoldText>
                <a href="mailto:">{email}</a>
              </DoctorLi>
            </DoctorUl>
          </DoctorFlex>
                </DoctorCV>
              </SpecialtiesBg>
            </DoctorData>
          </DoctorFlex>
        </DoctorFlex>
          <DoctorFlex column centered>
            <ProfileTitle   bold>
             <Trans i18nKey="doctorProfileBiography" />
            </ProfileTitle>
            <DoctorBiography>{biography}</DoctorBiography>
          </DoctorFlex>

      </DoctorFlex>
    </>
  );
}

export default DoctorInfoCard;
