import React from 'react'
import Image from '../../images/background.png'
import { useLottie } from "lottie-react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import groovyWalkAnimation from "../../lottie/header.json";

import { Trans, useTranslation} from 'react-i18next';


import {
    HeroContainer,
    HeroBg,
    HeroContent,
    HeroH1,
    HeroP,
    
} from './HeroElements'

const HeroSection = () => {
    useTranslation();

    const options = {
        animationData: groovyWalkAnimation,
        loop: true,
      };

      const { View } = useLottie(options);




  return (
    <>
    <HeroContainer>
        <HeroBg>
            <LazyLoadImage
                src={Image}
                alt="Softalmology background"
                width="100%"
                height="100%"
                color='white'
            />

        </HeroBg>
        <HeroContent>
            <div>
            <HeroH1><Trans i18nKey="headertitle"></Trans></HeroH1>
            <HeroP>
            <Trans i18nKey="headersubtitle"></Trans>
            </HeroP>
                       </div>
            <div>
                {View}
            </div>
        </HeroContent>

    </HeroContainer>
    
    </>
  )
}

    export default HeroSection