import React from "react";
import { CookieContainer } from "./CookiesElements";

function CookieSection() {
  return (
    <>
      <CookieContainer>
        <div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Arial !important;\nfont-size: 26px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Arial !important;\ncolor: #595959 !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Arial !important;\nfont-size: 19px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Arial !important;\nfont-size: 17px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: #595959 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: #3030F1 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\nword-break: break-word !important;\n        }\n",
            }}
          />
          <div data-custom-class="body">
            <div>
              <strong>
                <span style={{ fontSize: "26px" }}>
                  <span data-custom-class="title">COOKIE POLICY</span>
                </span>
              </strong>
            </div>
            <div>
              <br />
            </div>
            <div>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <strong>
                  <span style={{ fontSize: "15px" }}>
                    <span data-custom-class="subtitle">
                      Last updated{" "}
                      <bdt className="question">January 02, 2023</bdt>
                    </span>
                  </span>
                </strong>
              </span>
            </div>
            <div>
              <br />
            </div>
            <div>
              <br />
            </div>
            <div>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    This Cookie Policy explains how{" "}
                    <bdt className="question">Coding Solved LLC</bdt> ("
                    <strong>Company</strong>," "<strong>we</strong>," "
                    <strong>us</strong>," and "<strong>our</strong>") uses
                    cookies and similar technologies to recognize you when you
                    visit our website at{" "}
                    <bdt className="question">
                      <a
                        href="https://softalmology.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-custom-class="link"
                      >
                        https://softalmology.com
                      </a>
                    </bdt>{" "}
                    ("<strong>Website</strong>"). It explains what these
                    technologies are and why we use them, as well as your rights
                    to control our use of them.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    In some cases we may use cookies to collect personal
                    information, or that becomes personal information if we
                    combine it with other information.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(0, 0, 0)", fontSize: "15px" }}>
                  <strong>
                    <span data-custom-class="heading_1">What are cookies?</span>
                  </strong>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    Cookies are small data files that are placed on your
                    computer or mobile device when you visit a website. Cookies
                    are widely used by website owners in order to make their
                    websites work, or to work more efficiently, as well as to
                    provide reporting information.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    Cookies set by the website owner (in this case,{" "}
                    <bdt className="question">Coding Solved LLC</bdt>) are
                    called "first-party cookies." Cookies set by parties other
                    than the website owner are called "third-party cookies."
                    Third-party cookies enable third-party features or
                    functionality to be provided on or through the website
                    (e.g., advertising, interactive content, and analytics). The
                    parties that set these third-party cookies can recognize
                    your computer both when it visits the website in question
                    and also when it visits certain other websites.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(0, 0, 0)", fontSize: "15px" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      Why do we use cookies?
                    </span>
                  </strong>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    We use first-
                    <bdt className="block-component" /> and third-
                    <bdt className="statement-end-if-in-editor" />
                    party cookies for several reasons. Some cookies are required
                    for technical reasons in order for our Website to operate,
                    and we refer to these as "essential" or "strictly necessary"
                    cookies. Other cookies also enable us to track and target
                    the interests of our users to enhance the experience on our
                    Online Properties. <bdt className="block-component" />
                    Third parties serve cookies through our Website for
                    advertising, analytics, and other purposes.{" "}
                    <bdt className="statement-end-if-in-editor" />
                    This is described in more detail below.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                        <strong>
                          <span data-custom-class="heading_1">
                            How can I control cookies?
                          </span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    You have the right to decide whether to accept or reject
                    cookies. You can exercise your cookie rights by setting your
                    preferences in the Cookie Consent Manager. The Cookie
                    Consent Manager allows you to select which categories of
                    cookies you accept or reject. Essential cookies cannot be
                    rejected as they are strictly necessary to provide you with
                    services.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    The Cookie Consent Manager can be found in the notification
                    banner and on our website. If you choose to reject cookies,
                    you may still use our website though your access to some
                    functionality and areas of our website may be restricted.
                    You may also set or amend your web browser controls to
                    accept or refuse cookies.&nbsp;
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    The specific types of first- and third-party cookies served
                    through our Website and the purposes they perform are
                    described in the table below (please note that the
                    specific&nbsp;
                  </span>
                  <span data-custom-class="body_text">
                    cookies served may vary depending on the specific Online
                    Properties you visit):
                  </span>
                </span>
              </span>
              <span style={{ fontSize: "15px" }} />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span
                data-custom-class="heading_2"
                style={{ color: "rgb(0, 0, 0)" }}
              >
                <span style={{ fontSize: "15px" }}>
                  <strong>
                    <u>
                      <br />
                      Unclassified cookies:
                    </u>
                  </strong>
                </span>
              </span>
            </div>
            <div>
              <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span data-custom-class="body_text">
                      These are cookies that have not yet been categorized. We
                      are in the process of classifying these cookies with the
                      help of their providers.
                    </span>
                  </span>
                </span>
              </p>
              <div>
                <span data-custom-class="body_text">
                  <section
                    data-custom-class="body_text"
                    style={{
                      width: "100%",
                      border: "1px solid #e6e6e6",
                      margin: "0 0 10px",
                      borderRadius: "3px",
                    }}
                  >
                    <div
                      style={{
                        padding: "8px 13px",
                        borderBottom: "1px solid #e6e6e6",
                      }}
                    >
                      <table>
                        <tbody>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Name:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                m
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: 0,
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Purpose:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                __________
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Provider:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                m.stripe.com
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Service:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                __________ &nbsp;
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Country:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                United States
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Type:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                server_cookie
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Expires in:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                1 year 11 months 29 days
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                  <section
                    data-custom-class="body_text"
                    style={{
                      width: "100%",
                      border: "1px solid #e6e6e6",
                      margin: "0 0 10px",
                      borderRadius: "3px",
                    }}
                  >
                    <div
                      style={{
                        padding: "8px 13px",
                        borderBottom: "1px solid #e6e6e6",
                      }}
                    >
                      <table>
                        <tbody>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Name:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                i18nextLng
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: 0,
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Purpose:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                __________
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Provider:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                softalmology.com
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Service:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                __________ &nbsp;
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Country:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                United States
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Type:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                html_local_storage
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Expires in:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                persistent
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                </span>
              </div>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(0, 0, 0)", fontSize: "15px" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      How can I control cookies on my browser?
                    </span>
                  </strong>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span data-custom-class="body_text">
                As the means by which you can refuse cookies through your web
                browser controls vary from browser to browser, you should visit
                your browser's help menu for more information. The following is
                information about how to manage cookies on the most popular
                browsers:
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="link">Chrome</span>
                </a>
              </li>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="link">Internet Explorer</span>
                </a>
              </li>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                  rel="noopener noreferrer"
                  target="_blank"
                  
                >
                  <span data-custom-class="link">Firefox</span>
                </a>
              </li>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac"
                  rel="noopener noreferrer"
                  target="_blank"
                  

                >
                  <span data-custom-class="link">Safari</span>
                </a>
              </li>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="link">Edge</span>
                </a>
              </li>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="https://help.opera.com/en/latest/web-preferences/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="link">Opera</span>
                </a>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span data-custom-class="body_text">
                In addition, most advertising networks offer you a way to opt
                out of targeted advertising. If you would like to find out more
                information, please visit:
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="http://www.aboutads.info/choices/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="link">
                    Digital Advertising Alliance
                  </span>
                </a>
              </li>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="https://youradchoices.ca/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="link">
                    Digital Advertising Alliance of Canada
                  </span>
                </a>
              </li>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="http://www.youronlinechoices.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="body_text">
                    <span data-custom-class="link">
                      European Interactive Digital Advertising Alliance
                    </span>
                  </span>
                </a>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(0, 0, 0)", fontSize: "15px" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      What about other tracking technologies, like web beacons?
                    </span>
                  </strong>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    Cookies are not the only way&nbsp;
                  </span>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      to recognize or track visitors to a website. We may use
                      other, similar technologies from time to time, like web
                      beacons (sometimes called "tracking pixels" or "clear
                      gifs"). These are tiny graphics files that contain a
                      unique identifier that enables us to recognize when
                      someone has visited our Website
                      <bdt className="block-component" /> or opened an email
                      including them
                      <bdt className="statement-end-if-in-editor" />. This
                      allows us, for example, to monitor&nbsp;
                    </span>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          the traffic patterns of users from one page within a
                          website to another, to deliver or communicate with
                          cookies, to understand whether you have come to the
                          website from an online advertisement displayed on a
                          third-party website, to improve site performance, and
                          to measure the success of email marketing campaigns.
                          In many instances, these technologies are reliant on
                          cookies to function properly, and so declining cookies
                          will impair their functioning.
                        </span>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span style={{ color: "rgb(0, 0, 0)" }}>
                        <strong>
                          <span data-custom-class="heading_1">
                            Do you use Flash cookies or Local Shared Objects?
                          </span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  Websites may also use so-called "Flash Cookies" (also known as
                  Local Shared Objects or "LSOs") to, among other things,
                  collect and store information about your use of our services,
                  fraud prevention, and for other site operations.
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  If you do not want Flash Cookies stored on your computer, you
                  can adjust the settings of your Flash player to block Flash
                  Cookies storage using the tools contained in the&nbsp;
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(48, 48, 241)" }}>
                  <a
                    data-custom-class="link"
                    href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                    target="_BLANK"
                    rel="noopener noreferrer"
                  >
                    <span style={{ fontSize: "15px" }}>
                      Website Storage Settings Panel
                    </span>
                  </a>
                </span>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  . You can also control Flash Cookies by going to the&nbsp;
                </span>
                <span style={{ color: "rgb(48, 48, 241)" }}>
                  <a
                    data-custom-class="link"
                    href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
                    target="_BLANK"
                    rel="noopener noreferrer"

                  >
                    <span style={{ fontSize: "15px" }}>
                      Global Storage Settings Panel
                    </span>
                  </a>
                </span>
              </span>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">&nbsp;and&nbsp;</span>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    following the instructions (which may include instructions
                    that explain, for example, how to delete existing Flash
                    Cookies (referred to "information" on the Macromedia site),
                    how to prevent Flash LSOs from being placed on your computer
                    without your being asked, and (for Flash Player 8 and later)
                    how to block Flash Cookies that are not being delivered by
                    the operator of the page you are on at the time).
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Please note that setting the Flash Player to restrict or
                    limit acceptance of Flash Cookies may reduce or impede the
                    functionality of some Flash applications, including,
                    potentially, Flash applications used in connection with our
                    services or online content.
                  </span>
                </span>
              </span>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: "15px", color: "rgb(0, 0, 0)" }}
                          >
                            <strong>
                              <span data-custom-class="heading_1">
                                Do you serve targeted advertising?
                              </span>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  Third parties may serve cookies on your computer or mobile
                  device to serve advertising through our Website. These
                  companies may use information about your visits to this and
                  other websites in order to provide relevant advertisements
                  about goods and services that you may be interested in. They
                  may also employ technology that is used to measure the
                  effectiveness of advertisements. They can accomplish this by
                  using cookies or web beacons to collect information about your
                  visits to this and other sites in order to provide relevant
                  advertisements about goods and services of potential interest
                  to you. The information collected through this process does
                  not enable us or them to identify your name, contact details,
                  or other details that directly identify you unless you choose
                  to provide these.
                </span>
              </span>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      How often will you update this Cookie Policy?
                    </span>
                  </strong>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">We may update&nbsp;</span>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      this Cookie Policy from time to time in order to reflect,
                      for example, changes to the cookies we use or for other
                      operational, legal, or regulatory reasons. Please
                      therefore revisit this Cookie Policy regularly to stay
                      informed about our use of cookies and related
                      technologies.
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      The date at the top of this Cookie Policy indicates when
                      it was last updated.
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span data-custom-class="heading_1">
                        Where can I get further information?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      If you have any questions about our use of cookies or
                      other technologies, please email us at{" "}
                      <bdt className="question">admin@codingsolved.com</bdt> or
                      by post to:
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span data-custom-class="body_text">
                <br />
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">Coding Solved LLC</bdt>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <bdt className="question">
                  <span data-custom-class="body_text">
                    4187 N Stone Cliff dr
                  </span>
                </bdt>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <bdt className="question">Tucson</bdt>
                  <bdt className="block-component" />
                  <bdt className="block-component" />,{" "}
                  <bdt className="question">AZ</bdt>
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />{" "}
                  <bdt className="question">85705</bdt>
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <bdt className="question">United States</bdt>
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="else-block" />
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor">
                    <bdt className="block-component" />
                  </bdt>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor">
                    Phone: <bdt className="question">(+1)9289752929</bdt>
                  </bdt>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component" />
                </span>
              </span>
            </div>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    ",
              }}
            />
          </div>
          <div
            style={{
              color: "#595959",
              fontSize: "14px",
              fontFamily: "Arial",
              paddingTop: "16px",
            }}
          >
            This cookie policy was created using Termly's{" "}
            <a
              style={{ color: "rgb(48, 48, 241) !important" }}
              href="https://termly.io/products/cookie-consent-manager/"
            >
              Cookie Consent Manager
            </a>
            .
          </div>
          <div data-custom-class="body">
            <div>
              <strong>
                <span style={{ fontSize: "26px" }}>
                  <span data-custom-class="title">COOKIE POLICY</span>
                </span>
              </strong>
            </div>
            <div>
              <br />
            </div>
            <div>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <strong>
                  <span style={{ fontSize: "15px" }}>
                    <span data-custom-class="subtitle">
                      Last updated{" "}
                      <bdt className="question">January 02, 2023</bdt>
                    </span>
                  </span>
                </strong>
              </span>
            </div>
            <div>
              <br />
            </div>
            <div>
              <br />
            </div>
            <div>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    This Cookie Policy explains how{" "}
                    <bdt className="question">Coding Solved LLC</bdt> ("
                    <strong>Company</strong>," "<strong>we</strong>," "
                    <strong>us</strong>," and "<strong>our</strong>") uses
                    cookies and similar technologies to recognize you when you
                    visit our website at{" "}
                    <bdt className="question">
                      <a
                        href="https://softalmology.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-custom-class="link"
                      >
                        https://softalmology.com
                      </a>
                    </bdt>{" "}
                    ("<strong>Website</strong>"). It explains what these
                    technologies are and why we use them, as well as your rights
                    to control our use of them.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    In some cases we may use cookies to collect personal
                    information, or that becomes personal information if we
                    combine it with other information.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(0, 0, 0)", fontSize: "15px" }}>
                  <strong>
                    <span data-custom-class="heading_1">What are cookies?</span>
                  </strong>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    Cookies are small data files that are placed on your
                    computer or mobile device when you visit a website. Cookies
                    are widely used by website owners in order to make their
                    websites work, or to work more efficiently, as well as to
                    provide reporting information.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    Cookies set by the website owner (in this case,{" "}
                    <bdt className="question">Coding Solved LLC</bdt>) are
                    called "first-party cookies." Cookies set by parties other
                    than the website owner are called "third-party cookies."
                    Third-party cookies enable third-party features or
                    functionality to be provided on or through the website
                    (e.g., advertising, interactive content, and analytics). The
                    parties that set these third-party cookies can recognize
                    your computer both when it visits the website in question
                    and also when it visits certain other websites.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(0, 0, 0)", fontSize: "15px" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      Why do we use cookies?
                    </span>
                  </strong>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    We use first-
                    <bdt className="block-component" /> and third-
                    <bdt className="statement-end-if-in-editor" />
                    party cookies for several reasons. Some cookies are required
                    for technical reasons in order for our Website to operate,
                    and we refer to these as "essential" or "strictly necessary"
                    cookies. Other cookies also enable us to track and target
                    the interests of our users to enhance the experience on our
                    Online Properties. <bdt className="block-component" />
                    Third parties serve cookies through our Website for
                    advertising, analytics, and other purposes.{" "}
                    <bdt className="statement-end-if-in-editor" />
                    This is described in more detail below.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                        <strong>
                          <span data-custom-class="heading_1">
                            How can I control cookies?
                          </span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    You have the right to decide whether to accept or reject
                    cookies. You can exercise your cookie rights by setting your
                    preferences in the Cookie Consent Manager. The Cookie
                    Consent Manager allows you to select which categories of
                    cookies you accept or reject. Essential cookies cannot be
                    rejected as they are strictly necessary to provide you with
                    services.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    The Cookie Consent Manager can be found in the notification
                    banner and on our website. If you choose to reject cookies,
                    you may still use our website though your access to some
                    functionality and areas of our website may be restricted.
                    You may also set or amend your web browser controls to
                    accept or refuse cookies.&nbsp;
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    The specific types of first- and third-party cookies served
                    through our Website and the purposes they perform are
                    described in the table below (please note that the
                    specific&nbsp;
                  </span>
                  <span data-custom-class="body_text">
                    cookies served may vary depending on the specific Online
                    Properties you visit):
                  </span>
                </span>
              </span>
              <span style={{ fontSize: "15px" }}></span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span
                data-custom-class="heading_2"
                style={{ color: "rgb(0, 0, 0)" }}
              >
                <span style={{ fontSize: "15px" }}>
                  <strong>
                    <u>
                      <br />
                      Unclassified cookies:
                    </u>
                  </strong>
                </span>
              </span>
            </div>
            <div>
              <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span data-custom-class="body_text">
                      These are cookies that have not yet been categorized. We
                      are in the process of classifying these cookies with the
                      help of their providers.
                    </span>
                  </span>
                </span>
              </p>
              <div>
                <span data-custom-class="body_text">
                  <section
                    data-custom-class="body_text"
                    style={{
                      width: "100%",
                      border: "1px solid #e6e6e6",
                      margin: "0 0 10px",
                      borderRadius: "3px",
                    }}
                  >
                    <div
                      style={{
                        padding: "8px 13px",
                        borderBottom: "1px solid #e6e6e6",
                      }}
                    >
                      <table>
                        <tbody>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Name:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                m
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: 0,
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Purpose:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                __________
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Provider:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                m.stripe.com
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Service:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                __________ &nbsp;
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Country:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                United States
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Type:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                server_cookie
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Expires in:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                1 year 11 months 29 days
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                  <section
                    data-custom-class="body_text"
                    style={{
                      width: "100%",
                      border: "1px solid #e6e6e6",
                      margin: "0 0 10px",
                      borderRadius: "3px",
                    }}
                  >
                    <div
                      style={{
                        padding: "8px 13px",
                        borderBottom: "1px solid #e6e6e6",
                      }}
                    >
                      <table>
                        <tbody>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Name:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                i18nextLng
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: 0,
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Purpose:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                __________
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Provider:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                softalmology.com
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Service:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                __________ &nbsp;
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Country:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                United States
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Type:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                html_local_storage
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: "12px",
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: "80px",
                              }}
                            >
                              Expires in:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                persistent
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                </span>
              </div>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(0, 0, 0)", fontSize: "15px" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      How can I control cookies on my browser?
                    </span>
                  </strong>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span data-custom-class="body_text">
                As the means by which you can refuse cookies through your web
                browser controls vary from browser to browser, you should visit
                your browser's help menu for more information. The following is
                information about how to manage cookies on the most popular
                browsers:
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="link">Chrome</span>
                </a>
              </li>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="link">Internet Explorer</span>
                </a>
              </li>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="link">Firefox</span>
                </a>
              </li>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="link">Safari</span>
                </a>
              </li>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="link">Edge</span>
                </a>
              </li>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="https://help.opera.com/en/latest/web-preferences/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="link">Opera</span>
                </a>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span data-custom-class="body_text">
                In addition, most advertising networks offer you a way to opt
                out of targeted advertising. If you would like to find out more
                information, please visit:
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="http://www.aboutads.info/choices/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="link">
                    Digital Advertising Alliance
                  </span>
                </a>
              </li>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="https://youradchoices.ca/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="link">
                    Digital Advertising Alliance of Canada
                  </span>
                </a>
              </li>
              <li style={{ lineHeight: "1.5" }}>
                <a
                  href="http://www.youronlinechoices.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span data-custom-class="body_text">
                    <span data-custom-class="link">
                      European Interactive Digital Advertising Alliance
                    </span>
                  </span>
                </a>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(0, 0, 0)", fontSize: "15px" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      What about other tracking technologies, like web beacons?
                    </span>
                  </strong>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    Cookies are not the only way&nbsp;
                  </span>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      to recognize or track visitors to a website. We may use
                      other, similar technologies from time to time, like web
                      beacons (sometimes called "tracking pixels" or "clear
                      gifs"). These are tiny graphics files that contain a
                      unique identifier that enables us to recognize when
                      someone has visited our Website
                      <bdt className="block-component" /> or opened an email
                      including them
                      <bdt className="statement-end-if-in-editor" />. This
                      allows us, for example, to monitor&nbsp;
                    </span>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          the traffic patterns of users from one page within a
                          website to another, to deliver or communicate with
                          cookies, to understand whether you have come to the
                          website from an online advertisement displayed on a
                          third-party website, to improve site performance, and
                          to measure the success of email marketing campaigns.
                          In many instances, these technologies are reliant on
                          cookies to function properly, and so declining cookies
                          will impair their functioning.
                        </span>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span style={{ color: "rgb(0, 0, 0)" }}>
                        <strong>
                          <span data-custom-class="heading_1">
                            Do you use Flash cookies or Local Shared Objects?
                          </span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  Websites may also use so-called "Flash Cookies" (also known as
                  Local Shared Objects or "LSOs") to, among other things,
                  collect and store information about your use of our services,
                  fraud prevention, and for other site operations.
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  If you do not want Flash Cookies stored on your computer, you
                  can adjust the settings of your Flash player to block Flash
                  Cookies storage using the tools contained in the&nbsp;
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(48, 48, 241)" }}>
                  <a
                    data-custom-class="link"
                    href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                    target="_BLANK"
                    rel="noopener noreferrer"
                  >
                    <span style={{ fontSize: "15px" }}>
                      Website Storage Settings Panel
                    </span>
                  </a>
                </span>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  . You can also control Flash Cookies by going to the&nbsp;
                </span>
                <span style={{ color: "rgb(48, 48, 241)" }}>
                  <a
                    data-custom-class="link"
                    href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
                    target="_BLANK"
                    rel="noopener noreferrer"
                  >
                    <span style={{ fontSize: "15px" }}>
                      Global Storage Settings Panel
                    </span>
                  </a>
                </span>
              </span>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">&nbsp;and&nbsp;</span>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    following the instructions (which may include instructions
                    that explain, for example, how to delete existing Flash
                    Cookies (referred to "information" on the Macromedia site),
                    how to prevent Flash LSOs from being placed on your computer
                    without your being asked, and (for Flash Player 8 and later)
                    how to block Flash Cookies that are not being delivered by
                    the operator of the page you are on at the time).
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Please note that setting the Flash Player to restrict or
                    limit acceptance of Flash Cookies may reduce or impede the
                    functionality of some Flash applications, including,
                    potentially, Flash applications used in connection with our
                    services or online content.
                  </span>
                </span>
              </span>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: "15px", color: "rgb(0, 0, 0)" }}
                          >
                            <strong>
                              <span data-custom-class="heading_1">
                                Do you serve targeted advertising?
                              </span>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  Third parties may serve cookies on your computer or mobile
                  device to serve advertising through our Website. These
                  companies may use information about your visits to this and
                  other websites in order to provide relevant advertisements
                  about goods and services that you may be interested in. They
                  may also employ technology that is used to measure the
                  effectiveness of advertisements. They can accomplish this by
                  using cookies or web beacons to collect information about your
                  visits to this and other sites in order to provide relevant
                  advertisements about goods and services of potential interest
                  to you. The information collected through this process does
                  not enable us or them to identify your name, contact details,
                  or other details that directly identify you unless you choose
                  to provide these.
                </span>
              </span>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      How often will you update this Cookie Policy?
                    </span>
                  </strong>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">We may update&nbsp;</span>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      this Cookie Policy from time to time in order to reflect,
                      for example, changes to the cookies we use or for other
                      operational, legal, or regulatory reasons. Please
                      therefore revisit this Cookie Policy regularly to stay
                      informed about our use of cookies and related
                      technologies.
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      The date at the top of this Cookie Policy indicates when
                      it was last updated.
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span data-custom-class="heading_1">
                        Where can I get further information?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      If you have any questions about our use of cookies or
                      other technologies, please email us at{" "}
                      <bdt className="question">admin@codingsolved.com</bdt> or
                      by post to:
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span data-custom-class="body_text">
                <br />
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">Coding Solved LLC</bdt>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <bdt className="question">
                  <span data-custom-class="body_text">
                    4187 N Stone Cliff dr
                  </span>
                </bdt>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <bdt className="question">Tucson</bdt>
                  <bdt className="block-component" />
                  <bdt className="block-component" />,{" "}
                  <bdt className="question">AZ</bdt>
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />{" "}
                  <bdt className="question">85705</bdt>
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <bdt className="question">United States</bdt>
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="else-block" />
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor">
                    <bdt className="block-component" />
                  </bdt>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor">
                    Phone: <bdt className="question">(+1)9289752929</bdt>
                  </bdt>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component" />
                </span>
              </span>
            </div>
          </div>
        </div>
      </CookieContainer>
    </>
  );
}

export default CookieSection;
