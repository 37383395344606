import React from 'react'
import { ErrorContainer, ErrorImage, ErrorSubtitle, ErrorText, ErrorTitle } from './ErrorElements'
import errorSticker from '../../images/error404.png';
import ContactSection from '../ContactSection';
function ErrorDoctorNotFound() {
  return (
   <ErrorContainer>
      <ErrorImage src={errorSticker} />
      <ErrorTitle>
        Doctor not found
      </ErrorTitle>
      <ErrorSubtitle>
        The doctor you are looking for does not exist
      </ErrorSubtitle>

      <ErrorText>
        Si crees que esto es un error, por favor contacta con nosotros
      </ErrorText>
       
       <ContactSection/>
   </ErrorContainer>
  )
}

export default ErrorDoctorNotFound