import React from "react";
import { useLottie } from "lottie-react";
import groovyWalkAnimation from "../../lottie/adaptative.json";

import {
  DownloadContainer,
  DownloadWrapper,
  DownloadRow,
  LottieContainer,
  DownloadTitle,
  DownloadSubtitle,
  DownloadBtnWrapper,
  DownloadColumn,
} from "./DownloadElements";

import { ButtonHref } from "../ButtonElement";
import { Trans, useTranslation } from "react-i18next";
import { getAnalytics, logEvent } from "firebase/analytics";

function DownloadSection() {
  useTranslation();
  const options = {
    animationData: groovyWalkAnimation,
    loop: true,
  };

  const { View } = useLottie(options);

  function saveAnalyticEvent(event, data) {
    try {
      const analytics = getAnalytics();
      logEvent(analytics, event, data);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <>
      <DownloadContainer>
        <DownloadWrapper>
          <DownloadRow>
            <LottieContainer>{View}</LottieContainer>
          </DownloadRow>
          <DownloadRow>
            <DownloadColumn>

            <DownloadTitle>
              <Trans i18nKey="downloadTab"></Trans>
            
            </DownloadTitle>
              <Trans i18nKey="downloadTab"></Trans>
            </DownloadColumn>
            <DownloadSubtitle>

              <Trans i18nKey="downloadSubtitle"></Trans>
            </DownloadSubtitle>
            <DownloadSubtitle>
              <Trans i18nKey="downloadTitle"></Trans>
            </DownloadSubtitle>
            <DownloadBtnWrapper>
              <ButtonHref
                href="https://my.softalmology.com/"
                target={"_blank"}
                onClick={() =>
                  saveAnalyticEvent("download_app", { platform: "web" })
                }
              >
                Web
              </ButtonHref>
              <ButtonHref
                href="https://play.google.com/store/apps/details?id=com.codingsolved.softalmology"
                target={"_blank"}
                onClick={() =>
                  saveAnalyticEvent("download_app", { platform: "android" })
                }
              >
                Android
              </ButtonHref>
              <ButtonHref
                href="https://apps.apple.com/us/app/softalmology/id6443972541"
                target={"_blank"}
                onClick={() =>
                  saveAnalyticEvent("download_app", { platform: "ios" })
                }
              >
                iOS
              </ButtonHref>
            </DownloadBtnWrapper>
          </DownloadRow>
        </DownloadWrapper>
      </DownloadContainer>
    </>
  );
}

export default DownloadSection;
