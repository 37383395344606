import styled from "styled-components";

// main container has 2 colors, first is blue and second is white and has cards in middle
export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  padding: 0;
  margin: 0;
  justify-content: start;
  margin-bottom: 1%;

  align-items: center;
  text-align: center;
  transition: all 0.2s ease-in-out;
  height: auto;

  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
`;

// green card with like icon
export const SuccessCard = styled.div`
  background: green;
  height: 340px;
  width: 100%;
`;

export const Img = styled.img`
  height: 50%;
  width: 50%;
  margin-bottom: 10px;
  object-fit: contain;
`;

export const ThanksCard = styled.div`
  display: flex;
  height: 340px;
  background: rgb(140, 0, 255);
  padding: 30px;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
`;

export const Title = styled.h1`
  font-size: 3.5rem;
  color: black;
  margin-bottom: 20px;
  color: #fff;
  @media screen and (max-width: 1024px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const Text = styled.p`
  font-size: 2rem;
  margin-bottom: 10px;
  color: #fff;

  @media screen and (max-width: 1024px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }

  @mwedia screen and (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

export const NextStepContainer = styled.div`
  display: flex;
  background: #fff;
  padding: 30px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: start;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const NextStepTitle = styled.h1`
  font-size: 1.5rem;
  color: black;
  margin-bottom: 20px;
  color: #000;
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const CenteredDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    background: #fff;
    padding: 20px;
    margin: 0;
    transition: all 0.2s ease-in-out;
    @media screen and (max-width: 768px) {
        height: 1100px;
    }
    @media screen and (max-width: 480px) {
        height: 1300px;
    }
`;


export const NextStepText = styled.p`
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #000;

  @media screen and (max-width: 1024px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

export const NextStepDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  text-align: start;
  width: 100vw;
`;

export const Video = styled.video`
  width: 250px;
  height: 100%;
  margin-left: 30px;
  object-fit: contain;
`;
