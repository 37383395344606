import styled from "styled-components";

export const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #fff;
    color: #000;
    text-align: center;
    padding: 0 1rem;
    line-height: 1.4;
    font-size: 1.2rem;
`;

export const ErrorTitle = styled.h1`
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
`;

export const ErrorSubtitle = styled.p`
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
`;

export const ErrorText = styled.p`
    font-size: 1.2rem;
    font-weight: normal;
    margin-bottom: 4rem;
    
`;


export const ErrorImage = styled.img`
    width: 10%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
`;



