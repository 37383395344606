import React, { useState } from 'react'
import AboutSection from '../components/AboutSection'
import { FeatureSection } from '../components/FeaturesSection/'
import HeroSection from '../components/HeroSection'
import Navbar from '../components/Navbar'
import { ProcessSection } from '../components/ProcessSection'
import Sidebar from '../components/Sidebar'
import { PricingSection } from '../components/PricingSection'
import DownloadSection from '../components/DownloadSection'
import FooterSection from '../components/FooterSection'
import FAQSection from '../components/FAQSection'
import ContactSection from '../components/ContactSection'
import {useTranslation} from 'react-i18next';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }


  useTranslation();
  return (

    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <FeatureSection />
      <AboutSection />
      <ProcessSection />
      <PricingSection />
     <DownloadSection />
      <FAQSection />
      <ContactSection/>
      <FooterSection />
    </>
  )
}

export default Home