import React from "react";
import Card from "react-bootstrap/Card";
import googleicon from "../../images/google.svg";
import bellicon from "../../images/bell.svg";
import updateicon from "../../images/update.svg";
import network from "../../images/network.svg";

import {
  ProcessContainer,
  ProcessTitle,
  ProcessSubtitle,
  ProcessDescription,
  ProcessCard,
} from "./ProcessElements";
import {Trans, useTranslation } from "react-i18next";

import { Button } from "../ButtonElement";
import {
  Column1,
  FeaturesRow,
  FeaturesWrapper,
  Heading,
  TopLine,
  Description,
} from "../FeaturesSection/FeaturesElements";
import { FeatureItem } from "../FeaturesSection";
import ContactSection from "../ContactSection";
import { getAnalytics, logEvent } from "firebase/analytics";

const ProcessSection = () => {
  useTranslation();

  function saveAnalyticEvent(event, data) {
    try{
      const analytics = getAnalytics();
      logEvent(analytics, event, data);
    }catch(e){console.log(e)}
    
  }
  
  return (
    <ProcessContainer>
      <ProcessTitle>
        <Trans i18nKey="processTab"></Trans>
      </ProcessTitle>
      <ProcessSubtitle>
        <Trans i18nKey="processTitle"></Trans>
      </ProcessSubtitle>
      <ProcessDescription>
        <Trans i18nKey="processSubtitleMain"></Trans>
      </ProcessDescription>
      <ProcessCard>
        <Card style={{ width: "20rem", height: "420px", padding: "30px" }}>
          <Card.Img variant="top" src={bellicon} width={100} height={100} />
          <Card.Body style={{ height: "200px" }}>
            <Card.Title style={{ fontWeight: "bold" }}>
              <Trans i18nKey="processFirstPointTitle"></Trans>
            </Card.Title>
            <Card.Text>
              <Trans i18nKey="processFirstPointSubTitle"></Trans>
            </Card.Text>
          </Card.Body>
          <Card.Body>
            <Button to="process" onClick={() => saveAnalyticEvent("learn_more", { package: "ready_to_use"})}>
              <Trans i18nKey="learnMoreButton"></Trans>
            </Button>
          </Card.Body>
        </Card>
        <Card style={{ width: "20rem", height: "420px", padding: "30px" }}>
          <Card.Img variant="top" src={updateicon} width={100} height={100} />
          <Card.Body style={{ height: "200px" }}>
            <Card.Title style={{ fontWeight: "bold" }}>
              <Trans i18nKey="processSecondPointTitle"></Trans>
            </Card.Title>
            <Card.Text>
              <Trans i18nKey="processSecondPointSubTitle"></Trans>
            </Card.Text>
          </Card.Body>
          <Card.Body>
            <Button to="process" onClick={() => saveAnalyticEvent("learn_more", { package: "frequent_updates"})}>
              <Trans i18nKey="learnMoreButton"></Trans>
            </Button>
          </Card.Body>
        </Card>
        <Card style={{ width: "20rem", height: "420px", padding: "30px" }}>
          <Card.Img variant="top" src={network} width={100} height={100} />
          <Card.Body style={{ height: "200px" }}>
            <Card.Title style={{ fontWeight: "bold" }}>
              <Trans i18nKey="processThirdPointTitle"></Trans>
            </Card.Title>
            <Card.Text>
              <Trans i18nKey="processThirdPointSubTitle"></Trans>
            </Card.Text>
          </Card.Body>
          <Card.Body>
            <Button to="process" onClick={() => saveAnalyticEvent("learn_more", { package: "cloud_based"})}>
              <Trans i18nKey="learnMoreButton"></Trans>
            </Button>
          </Card.Body>
        </Card>
        <Card style={{ width: "20rem", height: "420px", padding: "30px" }}>
          <Card.Img variant="top" src={googleicon} width={100} height={100} />
          <Card.Body style={{ height: "200px" }}>
            <Card.Title style={{ fontWeight: "bold" }}>
              <Trans i18nKey="processFourthPointTitle"></Trans>
            </Card.Title>
            <Card.Text>
              <Trans i18nKey="processFourthPointSubTitle"></Trans>
            </Card.Text>
          </Card.Body>
          <Card.Body>
            <Button to="process" onClick={() => saveAnalyticEvent("learn_more", { package: "google_services"})}>
              <Trans i18nKey="learnMoreButton"></Trans>
            </Button>
          </Card.Body>
        </Card>
      </ProcessCard>
    </ProcessContainer>
  );
};

const ProcessTitleSection = (props) => {
  return (
    <>
      <ProcessContainer>
        <FeaturesWrapper>
          <FeaturesRow>
            <Column1>
              <TopLine></TopLine>
              <Heading>
                <Trans i18nKey="featuresTitle"></Trans>
              </Heading>
              <Description>
                <Trans i18nKey="processSubtitle"></Trans>
              </Description>

              <FeatureItem
                title={<Trans i18nKey="processFirstPointTitle" />}
                subtitle={<Trans i18nKey="processReadyToUseSubtitle"></Trans>}
                description={
                  <Trans i18nKey="processReadyToUseDescription"></Trans>
                }
                type="2"
                image={bellicon}
              />
              <FeatureItem
                title={<Trans i18nKey="processSecondPointTitle" />}
                subtitle={
                  <Trans i18nKey="processFrequentUpdatesSubtitle"></Trans>
                }
                description={
                  <Trans i18nKey="processFrequentUpdatesDescription"></Trans>
                }
                type="1"
                image={updateicon}
              />
              <FeatureItem
                title={<Trans i18nKey="processThirdPointTitle" />}
                subtitle={<Trans i18nKey="processCloudBasesSubtitle"></Trans>}
                description={
                  <Trans i18nKey="processCloudBasedDescription"></Trans>
                }
                type="2"
                image={network}
              />
              <FeatureItem
                title={<Trans i18nKey="processFourthPointTitle" />}
                subtitle={
                  <Trans i18nKey="processGoogleServicesSubtitle"></Trans>
                }
                description={
                  <Trans i18nKey="processGoogleServicesDescription"></Trans>
                }
                type="1"
                image={googleicon}
              />
            </Column1>
            <Column1>
              <ContactSection/>
            </Column1>
          </FeaturesRow>
        </FeaturesWrapper>
      </ProcessContainer>
    </>
  );
};

export { ProcessSection, ProcessTitleSection };
