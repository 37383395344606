import React from "react";

import check from "../../images/true.svg";
import none from "../../images/cancel.svg";
import { Button, } from "../ButtonElement";
import {

  PricingCard as PricingCardStyled,
  PricingCardTitle,
  PricingCardInfo,
  PricingCardPrice,
  PricingCardFeatures,
  PricingCardFeature,
  MiniText,
  BilledText,
  InvisibleText,
} from "./PricingElements";
import { LazyLoadImage } from "react-lazy-load-image-component";

import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import getStripe from "../../getStripe";
import { useTranslation, Trans } from "react-i18next";
import { SvgIcon } from "@mui/material";
import { getAnalytics, logEvent } from "firebase/analytics";

function PricingCard(props) {
  const softalmologyPackage = props.package;
  const { i18n } = useTranslation();


  async function handleCheckout(priceID) {

    try {
      const stripe = await getStripe();
      const { error } = await stripe.redirectToCheckout({
        lineItems: [
          {
            price: priceID,
            quantity: 1,
          },
        ],
        mode: "subscription",
        successUrl: "https://softalmology.com/success",
        cancelUrl: "https://softalmology.com/",
      });
      console.warn(error.message);
    } catch (error) {
      console.log(error);
    }
  }

  async function saveAnalyticEvent(event, data) {
    try {
      const analytics = getAnalytics();
      logEvent(analytics, event, data);
    } catch (e) { console.log(e) }

  }
  return (
    <>
      <PricingCardStyled>

        <LazyLoadImage
          src={softalmologyPackage.imagePath}
          alt={softalmologyPackage.name}
          width={150}
          height={150}
        />
        <PricingCardTitle>{softalmologyPackage.name}</PricingCardTitle>
        <PricingCardInfo>


          <PricingCardPrice>
            ${softalmologyPackage.price}/<Trans i18nKey="month"></Trans>
          </PricingCardPrice>
          <span>Per seat</span>
          <br></br>
          {i18n.language === "es" ? (
            softalmologyPackage.description_es.map((feature, index) => (
              <PricingCardFeatures key={index}>
              <h3>&#8226;</h3>{feature}
              </PricingCardFeatures>
            ))

          ) : (
            softalmologyPackage.description_en.map((feature, index) => ( 
              <PricingCardFeatures key={index}>
              <h3>&#8226;</h3>{feature}
              </PricingCardFeatures>
            ))
          )}


          
        </PricingCardInfo>
      </PricingCardStyled>
    </>
  );
}

export default PricingCard;
