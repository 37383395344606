import React from 'react'
import { useLottie } from "lottie-react";

import about from "../../lottie/about.json";
import { AiFillCheckCircle } from 'react-icons/ai'
import { Trans, useTranslation } from 'react-i18next';

import {
  AboutContainer,
  AboutWrapper,
  AboutRow,
  Lottie,
  BulletUL,
  BulletLI

} from './AboutElements'

import { Column1, TextWrapper, TopLine, Heading, Subtitle } from '../FeaturesSection/FeaturesElements';
const AboutSection = () => {
  useTranslation();
  const options = {
    animationData: about,
    loop: true,

  };

  const { View } = useLottie(options);

  return (
    <AboutContainer>
      <AboutWrapper>
        <AboutRow>
          <Lottie>
            {View}
          </Lottie>
        </AboutRow>
      </AboutWrapper>
      <AboutWrapper>
        <AboutRow>
          <Column1>
            <TextWrapper>
              <TopLine>Acerca de</TopLine>
              <Heading><Trans i18nKey="aboutTitle"></Trans></Heading>
              <Subtitle><Trans i18nKey="aboutSubtitle"></Trans></Subtitle>
              <BulletUL>
                <BulletLI><AiFillCheckCircle style={{ color: 'orange' }} /><Trans i18nKey="aboutFirstPoint"></Trans></BulletLI>
                <BulletLI><AiFillCheckCircle style={{ color: 'purple' }} /><Trans i18nKey="aboutSecondPoint"></Trans></BulletLI>
                <BulletLI><AiFillCheckCircle style={{ color: 'blue' }} /><Trans i18nKey="aboutThirdPoint"></Trans></BulletLI>
              </BulletUL>
             
            </TextWrapper>
          </Column1>
        </AboutRow>
      </AboutWrapper>
    </AboutContainer>

  )
}

export default AboutSection