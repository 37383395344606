import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Button = styled(Link)`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#01BF71' : '#010606')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#000' : '#01BF71')};
        color: ${({dark}) => (dark ? '#010606' : '#fff')};
    }  
`;

export const ButtonHref = styled.a`
border-radius: 50px;
background: ${({primary}) => (primary ? '#01BF71' : '#010606')};
white-space: nowrap;
padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
color: ${({dark}) => (dark ? '#010606' : '#fff')};
font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
outline: none;
border: none;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
transition: all 0.2s ease-in-out;
text-decoration: none;

&:hover {
    transition: all 0.2s ease-in-out;
    background: ${({primary}) => (primary ? '#000' : '#01BF71')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
}  
`;
export const Button2 = styled.button`
border-radius: 50px;
background: white;
white-space: nowrap;
padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
color: ${({dark}) => (!dark ? '#010606' : '#fff')};
font-size: ${({fontBig}) => (fontBig ? '20px' : '14px')};
background: ${({primary, success,dark}) => (primary ? 'white' : success ? '#01BF71' : dark ? 'black' : '#fff')};
font-weight: 600;
outline: none;
margin-left: 10px;
border: none;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;

transition: all 0.2s ease-in-out;
text-decoration: none;

&:hover {
    transition: all 0.2s ease-in-out;
    background: ${({primary}) => (primary ? '#fff' : '#01BF71')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
}
`;
export const Button3 = styled.button`
border-radius: 50px;
background: white;
white-space: nowrap;
padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
color: white;
background: black;
font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
font-weight: 600;
outline: none;
border: none;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
transition: all 0.2s ease-in-out;
text-decoration: none;

&:hover {
    transition: all 0.2s ease-in-out;
    background: ${({primary}) => (primary ? '#fff' : '#01BF71')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
}
`;

export const aHrefText = styled.a`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#01BF71' : '#010606')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff' : '#01BF71')};
    }
`;
