import styled from 'styled-components';

export const FooterContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0 30px;
height: auto;
position: relative;
z-index: 1;
`

export const FooterImageBg = styled.div`
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
object-fit: cover;
z-index: -1;

@media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
}
`

export const FooterImage = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`

export const FooterWrapper = styled.div`
    display: flex;
    z-index: 1;
    height: auto;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    justify-content:center;
    align-items: center;
    flex-direction: row;
    align-content: center;


    @media screen and (max-width: 1250px) {
        padding: 0 24px;
        flex-direction: column;
        gap: 0px;
    }

    @media screen and (max-width: 480px) {
        padding: 0 24px;
        // display 1 row and 1 column
        flex-direction: column;
        gap: 0px;
    }

`


export const FooterH1 = styled.h1`
    color: #fff;
    font-size: 24px;
    text-align: center;

    @media screen and (max-width: 480px) {
        font-size: 24px;
    }
`

export const FooterP = styled.p`
    margin-top: 10px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width: 480px) {
        font-size: 16px;
    }
`

export const FooterIcon = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`


export const Centered = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: auto;
    position: relative;
    z-index: 1;
    padding-top: 20px;
    padding-bottom: 20px;

`

