import React, { useState } from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import PrivacyPolicySection from '../components/PrivacySection'

function PrivacyPolicy() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
   <Sidebar isOpen={isOpen} toggle={toggle} />
    <Navbar toggle={toggle} />
    <PrivacyPolicySection/>
    
  
    </>
  )
}
export default PrivacyPolicy



