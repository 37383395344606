import styled from 'styled-components';
export const AboutContainer = styled.div`
    color: white;
    margin-top: 0px;
    padding-top: 0px;
    background: ${({lightBg}) => (!lightBg ? '#f8faff' : '#010606')};
    // display 2 rows and 1 column
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 100px;
    flex-direction: row;
    align-content: space-between;

    // if screen is less than 768px, column reverse
    @media screen and (max-width: 1250px) {
        padding: 100px 0;
        // display 1 row and 1 column
        flex-direction: column-reverse;
    }
`
export const AboutWrapper = styled.div`
    margin-top: 40px;
    display: flex;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
    `

export const AboutRow = styled.div` 
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
    }

` 


export const Lottie = styled.div`
   overflow: hidden;
    margin-bottom: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;

    // reduce width of lottie animation
    @media screen and (max-width: 768px) {
        display: none;
    }

    @media screen and (max-width: 480px) {
        width: 30%;
    }


`

export const BulletUL = styled.ul`
    list-style-type: none;
    margin: 0;
    color: #000;
    background-color: #f8faff;
    padding: 0;
    overflow: hidden;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 10px;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 10px;
    @media screen and (max-width: 768px) {
        width: 100%;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
    }
`

export const BulletLI = styled.li`
   
    justify-content: start;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    text-align: left;
    gap: 10px;
    width: 100%;
    height: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;

    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 10px;
    @media screen and (max-width: 768px) {
        width: 100%;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
    }
`
