import React, { useState } from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import CookieSection from '../components/CookiesSection'

function CookiesPage() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
   <Sidebar isOpen={isOpen} toggle={toggle} />
    <Navbar toggle={toggle} />
    <CookieSection/>
    
  
    </>
  )
}
export default CookiesPage



