import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import { Centered } from "../FooterSection/FooterElements";
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';


export default function ErrorPage() {
  const location = useLocation();
  console.error(location);
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>  
     <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
    <Centered>
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{location.state && location.state.message}</i>
      </p>
    </div>
    </Centered>

    </>
  );
}
