import styled from 'styled-components';

export const DownloadContainer = styled.div`
    color: #fff;
    margin-top: 0px;

    padding-top: 0px;
    background: ${({lightBg}) => (!lightBg ? 'white' : '#010606')};
    // display 2 rows and 1 column
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 100px;
    flex-direction: row;
    align-content: space-between;

    @media screen and (max-width: 1250px) {
        padding: 100px 0;
        // display 1 row and 1 column
        flex-direction: column;

    }

    @media screen and (max-width: 480px) {
        padding: 100px 0;
        // display 1 row and 1 column
        flex-direction: column;
    }
`
export const DownloadWrapper = styled.div`
    display: flex;

    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
    align-items: center;
    gap: 50px;
    flex-direction: row;
    align-content: space-between;

    @media screen and (max-width: 1250px) {
        padding: 0 24px;
        // display 1 row and 1 column
        flex-direction: column;
        gap: 0px;
    }

    @media screen and (max-width: 480px) {
        padding: 0 24px;
        // display 1 row and 1 column
        flex-direction: column;
        gap: 0px;
    }


    `

export const DownloadRow = styled.div`
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;

    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {
    }  
`

export const DownloadColumn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    `  



// lottie 
export const LottieContainer = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 500px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 50px;
    margin-right: 50px;
    background: white;
    border-radius: 10px;
    padding: 30px;

    @media screen and (max-width: 768px) {
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 50px;
        margin-right: 50px;
        background: white;
        border-radius: 10px;
        padding: 30px;
    }

    @media screen and (max-width: 480px) {
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 50px;
        margin-right: 50px;
        background: white;
        border-radius: 10px;
        padding: 30px;
    }  
`
// title
export const DownloadTitle = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;


    color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

// subtitle
export const DownloadSubtitle = styled.p`
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};
`

// container for the buttons
export const DownloadBtnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    // space between the buttons   
`

// button
export const DownloadButton = styled.a`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#01bf71' : '#010606')};
    white-space: nowrap;
    width: 150px;
    margin-top: 20px;

    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff' : '#01bf71')};
    }
`


