import React from "react";
import background from "../../images/screenshot-bg.jpg";
import icon from "../../images/softalmology-eye.png";
import { Trans, useTranslation } from 'react-i18next';

import {
  FooterContainer,
  FooterImageBg,
  FooterImage,
  FooterWrapper,
  FooterH1,
  FooterP,
  Centered,
} from "./FooterElements";

import {Button} from '../ButtonElement';
function FooterSection() {
  useTranslation();

  return (
    <>
      <FooterContainer>
        <FooterImageBg>
          <FooterImage src={background} />
        </FooterImageBg>
        <FooterWrapper>
          <Centered>
            <img src={icon} alt="softalmology icon" width={70} />
            <FooterH1>Softalmology</FooterH1>
            <FooterP>© 2022 Softalmology.  <Trans i18nKey="allrightsreserved"></Trans></FooterP>
          </Centered>
          <Centered>
            <FooterP>
            <Trans i18nKey="developedby">
                                </Trans>{" "}
              <a href="https://codingsolved.com/" target="_blank" rel="noopener noreferrer">
                CodingSolved
              </a>
            </FooterP>
        <Centered>
          <Button to="/data-request"><Trans i18nKey="privacyPolicy"></Trans></Button>
          <Button to="/cookies-privacy-policy"><Trans i18nKey="cookiesPolicy"></Trans></Button>
        </Centered>
          </Centered>
        </FooterWrapper>
      </FooterContainer>
    </>
  );
}

export default FooterSection;
