import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import DoctorInfoCard from "./DoctorInfo";
import { softalmologyDb} from "../../firebase";
import { getDoc, doc } from "firebase/firestore";
import ErrorDoctorNotFound from "../error/doctorNotFound";
import { getAnalytics, logEvent } from "firebase/analytics";

function DoctorProfileElement() {
  const { id } = useParams();
  const [user, setUser] = useState([]);

  useEffect(() => {
    const getDoctorUser = async () => {
      // get user in doc id
      try{
        const data = await getDoc(doc(softalmologyDb, "users", id));
        //console.log('smn' + id);
        setUser(data.data());
      }catch(error){
        console.log('error' + error);
      }
    };
    getDoctorUser();
  }, []);

  useEffect(() => {
    const analytics = getAnalytics();
    if (user) {
      logEvent(analytics, "page_view", {
        page_title: "Doctor Profile",
        user_id: user.id,
      })
    }else{
      logEvent(analytics, "page_view", {
        page_title: "Doctor Profile",
        user_id: "null",
      })
    }
  }, [user]);

  return (
    //  if user is != null then show doctor info else show error page
    <>
    {user &&
     <DoctorInfoCard  doctor = {user} />}

     {!user && 
      <ErrorDoctorNotFound/>}
    </>

     

  );
}

export default DoctorProfileElement;
