import React, { useState, useEffect } from "react";

import { collection, getDocs } from "firebase/firestore";
import { softalmologyDb } from "../../firebase";
import { Button } from "../ButtonElement";
import {
  PricingContainer,
  PricingBg,
  PricingTitle,
  PricingDescription,
  PricingWrapper,

  PricingHelperText,
} from "./PricingElements";
import {  Trans, useTranslation } from "react-i18next";

import img from "../../images/screenshot-bg.jpg";
import PricingCard from "./PricingCard.js";
import {  Toggle } from "./planSwitch";

import { getAnalytics, logEvent } from "firebase/analytics";

export const PricingSection = () => {
  useTranslation();

  const [packages, setPackages] = useState([]);
  const [isToggled, setToggle] = useState(true);


  useEffect(() => {
    const getPackages = async () => {
      const data = await getDocs(collection(softalmologyDb, "packagesinfo"));
      // data sort by price
      //setPackages(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

      // sort by price in ascending order
      setPackages(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).sort((a, b) => a.price - b.price));

    };
    getPackages();
  }, []);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, "page_view", {
      page_title: "Pricing",
      isToggled: isToggled ? "Monthly" : "Yearly",
    });
  }, [isToggled]);


  const logState = () => {
    setToggle(!isToggled);
  };


  return (
    <PricingContainer>
      <PricingBg src={img} />
      <PricingTitle>
        <Trans i18nKey="pricingTab"></Trans>
      </PricingTitle>
      <PricingDescription>
        <Trans i18nKey="pricingTitle"></Trans>

      </PricingDescription>
      {/* <Toggle
        label="Toggle me"
        toggled={!isToggled}
        onClick={logState}
      /> */}
      <PricingWrapper>
        {
          packages.map(
            (softalmologyPackage) =>
              softalmologyPackage.showInPage && (
                <PricingCard package={softalmologyPackage}></PricingCard>
              )
          )
        }
        
      </PricingWrapper>

      <Button secondary target="_blank" onClick={() => {
         const analytics = getAnalytics();
         logEvent(analytics, "click", {
          event_category: "Packages",
          event_label: "Learn More",
          value: "Learn More",
         });
       
         window.open("https://my.softalmology.com", "_blank");
      }}>
        <Trans i18nKey="getStartedButton"></Trans>
      </Button>

      <br></br>
      <PricingHelperText>
        <Trans i18nKey="contactUsTitle"></Trans> admin@codingsolved.com
      </PricingHelperText>
      <br></br>
      <br></br>
    </PricingContainer>
  );
};
