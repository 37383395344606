import React  from "react";
import { Button } from "../ButtonElement";
import HoverCard from "react-png-hovercard";

import support from "../../images/support.svg";
import calendar from "../../images/schedule (1).svg";
import easytouse from "../../images/easy-to-use.svg";
import medicalrecords from "../../images/medical-record (1).svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  FeaturesContainer,
  FeaturesWrapper,
  FeaturesRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  GridLayout,
  BackTextHeader,
  BackText,
  FrontContainer,
  Title,
  Description,
  Feature,
  FeatureText,
} from "./FeaturesElements";

import { Trans } from "react-i18next";
import ContactSection from "../ContactSection";

import { getAnalytics, logEvent } from "firebase/analytics";

class FeatureSection extends React.Component {
  
  saveAnalyticEvent(event, data) {
    try{
      const analytics = getAnalytics();
      logEvent(analytics, event, data);
    }catch(e){console.log(e)}
    
  }

  render() {

    return (
      <>
        <FeaturesContainer id="features">
          <FeaturesWrapper>
            <FeaturesRow>
              <Column1>
                <TextWrapper>
                  <TopLine>
                    <Trans i18nKey="featuresTab"></Trans>
                  </TopLine>
                  <Heading>
                    <Trans i18nKey="featuresTitle"></Trans>
                  </Heading>
                  <Subtitle>
                    <Trans i18nKey="featuresSubtitle"></Trans>
                  </Subtitle>
                  <BtnWrap>
                    <Button to="features" onClick={() => this.saveAnalyticEvent("click_learn_more", {feature: "features"})}>
                      <Trans i18nKey="learnMoreButton"></Trans>
                    </Button>
                  </BtnWrap>
                </TextWrapper>
              </Column1>
            </FeaturesRow>
          </FeaturesWrapper>
          <FeaturesWrapper>
            <GridLayout>
              <div className="ImageContent">
                <HoverCard
                  front={
                    <FrontContainer>
                      <LazyLoadImage
                        src={easytouse}
                        width={400}
                        height={100}
                        alt={"Softalmology is easy to use"}
                      />

                      <BackTextHeader>
                        <Trans i18nKey="featuresButtonEasyToUse"></Trans>
                      </BackTextHeader>
                    </FrontContainer>
                  }
                  back={
                    <div className="Back">
                      <BackTextHeader>
                        <Trans i18nKey="featuresButtonEasyToUse"></Trans>
                      </BackTextHeader>
                      <BackText>
                        <Trans i18nKey="betterExperience"></Trans>
                      </BackText>
                      <Button to="features" primary
                       onClick={() => this.saveAnalyticEvent("click_learn_more", {feature: "easy_to_use"})}>
                 
                        <Trans i18nKey="learnMoreButton"></Trans>
                      </Button>
                    </div>
                  }
                  animationSpeed={500}
                  height={300}
                  margin={10}
                />
              </div>
              <div className="ImageContent">
                <HoverCard
                  front={
                    <FrontContainer>
                      <LazyLoadImage
                        width={100}
                        height={100}
                        src={support}
                        alt="Softalmology has a fast support"
                        style={{ objectFit: "contain" }}
                      />
                      <BackTextHeader>
                        <Trans i18nKey="featuresButtonFastSupport"></Trans>
                      </BackTextHeader>
                    </FrontContainer>
                  }
                  back={
                    <div className="Back">
                      <BackTextHeader>
                        <Trans i18nKey="featuresButtonFastSupport"></Trans>
                      </BackTextHeader>
                      <BackText>
                        <Trans i18nKey="quickSupport"></Trans>
                      </BackText>
                      <Button primary to="features"  onClick={() => this.saveAnalyticEvent("click_learn_more", {feature: "fast_support"})}>
                        <Trans i18nKey="learnMoreButton"></Trans>
                      </Button>
                    </div>
                  }
                  animationSpeed={500}
                  height={300}
                  margin={10}
                />
              </div>
              <div className="ImageContent">
                <HoverCard
                  front={
                    <FrontContainer>
                      <LazyLoadImage
                        width={100}
                        height={100}
                        src={calendar}
                        alt="Softalmology has a daily appointment calendar"
                        style={{ objectFit: "contain" }}
                      />
                      <BackTextHeader>
                        <Trans i18nKey="featuresButtonDailyAppointments"></Trans>
                      </BackTextHeader>
                    </FrontContainer>
                  }
                  back={
                    <div className="Back">
                      <BackTextHeader>
                        <Trans i18nKey="featuresButtonDailyAppointments"></Trans>
                      </BackTextHeader>
                      <BackText>
                        <Trans i18nKey="realTimeWord"></Trans>
                      </BackText>
                      <Button primary to="features" onClick={() => this.saveAnalyticEvent("click_learn_more", {feature: "daily_appointments"})}>
                        <Trans i18nKey="learnMoreButton"></Trans>
                      </Button>
                    </div>
                  }
                  animationSpeed={500}
                  height={300}
                  margin={10}
                />
              </div>
              <div className="ImageContent">
                <HoverCard
                  front={
                    <FrontContainer>
                      <LazyLoadImage
                        width={100}
                        height={100}
                        src={medicalrecords}
                        alt="Softalmology has electronic medical records"
                        style={{ objectFit: "contain" }}
                      />
                      <BackTextHeader>
                        <Trans i18nKey="featuresButtonEMR"></Trans>
                      </BackTextHeader>
                    </FrontContainer>
                  }
                  back={
                    <div className="Back">
                      <BackTextHeader>
                        <Trans i18nKey="featuresButtonEMR"></Trans>
                      </BackTextHeader>
                      <BackText>
                        <Trans i18nKey="emrIntegration"></Trans>
                      </BackText>
                      <Button primary to="features" onClick={() => this.saveAnalyticEvent("click_learn_more", {feature: "emr"})}>
                        <Trans i18nKey="learnMoreButton"></Trans>
                      </Button>
                    </div>
                  }
                  animationSpeed={500}
                  height={300}
                  margin={10}
                />
              </div>
            </GridLayout>
          </FeaturesWrapper>
        </FeaturesContainer>
      </>
    );
  }
}

const FeatureTitle = () => {
  return (
    <>
      <FeaturesContainer id="features">
        <FeaturesWrapper>
          <FeaturesRow>
            <Column1>
              <TopLine></TopLine>
              <Heading>
                <Trans i18nKey="featuresTitle"></Trans>
              </Heading>
              <Description>
                <Trans i18nKey="featuresMoreDescription"></Trans>
              </Description>

              <FeatureItem
                title={<Trans i18nKey="featuresButtonEasyToUse"></Trans>}
                subtitle={<Trans i18nKey="featuresEasyToUseSubtitle"></Trans>}
                description={
                  <Trans i18nKey="featuresEasyToUseDescription"></Trans>
                }
                type="2"
                image={easytouse}
              />

              <FeatureItem
                title={<Trans i18nKey="featuresButtonFastSupport"></Trans>}
                subtitle={<Trans i18nKey="featuresFastSupportSubtitle"></Trans>}
                description={
                  <Trans i18nKey="featuresFastSupportDescription"></Trans>
                }
                type="1"
                image={support}
              />

              <FeatureItem
                title={
                  <Trans i18nKey="featuresButtonDailyAppointments"></Trans>
                }
                subtitle={
                  <Trans i18nKey="featuresDailyAppointmentsSubtitle"></Trans>
                }
                description={
                  <Trans i18nKey="featuresDailyAppointmentsDescription"></Trans>
                }
                type="2"
                image={calendar}
              />

              <FeatureItem
                title={<Trans i18nKey="featuresButtonEMR"></Trans>}
                subtitle={<Trans i18nKey="featuresEMRSubtitle"></Trans>}
                description={<Trans i18nKey="featuresEMRDescription"></Trans>}
                type="1"
                image={medicalrecords}
              />
            </Column1>

            <Column1>
              <ContactSection />
            </Column1>
          </FeaturesRow>
        </FeaturesWrapper>
      </FeaturesContainer>
    </>
  );
};

const FeatureItem = (props) => {
  return (
    <>
      <FeaturesContainer id="features">
        {props.type === "1" && (
          <Feature>
            <FeatureText>
              <Title>{props.title}</Title>
              <Subtitle>{props.subtitle}</Subtitle>
              <Description>{props.description}</Description>
            </FeatureText>
            <img src={props.image} alt="" width={150} />
          </Feature>
        )}

        {props.type === "2" && (
          <Feature reversed>
            <FeatureText>
              <Title>{props.title}</Title>
              <Subtitle>{props.subtitle}</Subtitle>
              <Description>{props.description}</Description>
            </FeatureText>
            <img src={props.image} alt="" width={150} />
          </Feature>
        )}
      </FeaturesContainer>
    </>
  );
};

// export default FeatureSection and FeatureTitle
export { FeatureSection, FeatureTitle, FeatureItem };
