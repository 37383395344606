import styled from 'styled-components';

export const FeaturesContainer = styled.div`
    color: #fff;
    margin-top: 0px;
    
    padding-top: 0px;
    background: ${({lightBg}) => (!lightBg ? 'white' : '#010606')};
    // display 2 rows and 1 column
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 100px;
    flex-direction: row;
    align-content: center;

    @media screen and (max-width: 1250px) {
        max-width: 100%;
        padding: 0 30px;
        // display 1 row and 1 column
        flex-direction: column;

    }

    @media screen and (max-width: 480px) {
        // display 1 row and 1 column
        padding: 0 0px;
        flex-direction: column;
    }


`
export const FeaturesWrapper = styled.div`
    display: flex;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
    `

export const FeaturesRow = styled.div`
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;

    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {

    }
`

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`

export const TextWrapper = styled.div`
    max-width: 540px;.
    padding-top: 0;
    padding-bottom: 60px;
`

export const TopLine = styled.p`
    color: #01bf71;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    font-weight: 600;
    color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }

`

export const Subtitle = styled.p`
    text-align: justify;
    width: 100%;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
    color: ${({darkText}) => (darkText ? '#010606' : '#010606')};
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`



export const GridLayout = styled.div`
    display: grid;
    // two columns and two rows
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    // gap between columns and rows
    grid-gap: 10px;
    // padding between the grid and the edges of the container
    padding: 10px;

    // size of the grid
    width: 100%;
    height: 100%;




    @media screen and (max-width: 768px) {
        // one column and two rows
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        
    }

    @media screen and (max-width: 480px) {
        // one column and two rows
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
`


export const BackTextHeader = styled.p`
    color: #01bf71;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    padding: 15px;
    margin: 20;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
    text-align: center;
    
`

export const BackText = styled.p`
    color: #010606;
    font-size: 16px;
    padding: 15px;
    margin: 20;

    margin-bottom: 16px;
`

export const BackButton = styled.button`
    background: #01bf71;
    white-space: nowrap;
    padding: 10px 22px;
    margin: 20;
    width: 100%;
    // in bottom
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;

    }
`


export const FrontContainer = styled.div`
    color: #fff;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;


    @media screen and (max-width: 768px) {
        height: 100%;
    }

    @media screen and (max-width: 480px) {
        height: 100%;
    }
`


export const FrontImageBg = styled.div`
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`


export const Title = styled.h1`
    color: #010606;
    font-size: 32px;
    text-align: center;
`

export const Description = styled.p`
    color: #010606;
    font-size: 18px;
    text-align: justify;
    margin: 20;
    line-height: 25px;
`


export const Feature = styled.div`
    color: #010606;
    font-size: 28px;
    text-align: center;
    display: flex;
    flex-direction: ${({reversed}) => (reversed ? 'row-reverse' : 'row')};
    justify-content: space-between;
    
    align-items: stretch;
    width: 100%;
    height: 100%;
    gap:40px;
    position: relative;
    z-index: 1;
    padding: 10px;
    margin-bottom: 40px;
    
    @media screen and (max-width: 768px) {
        height: 100%;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        
    }
    
    @media screen and (max-width: 480px) {
        height: 100%;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        

    }
`

export const FeatureImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0 0 10px 0;
    padding-right: 0;
    border-radius: 10px;

    @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
        margin: 0 0 10px 0;
        padding-right: 0;
        border-radius: 10px;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
        height: 100%;
        margin: 0 0 10px 0;
        border-radius: 10px;
    }
`

export const FeatureText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    padding: 0;
    margin: 20;
    text-align: center;
    color: #010606;
    font-size: 18px;
    line-height: 24px;
    text-align: justify;
    padding: 10px;
    margin: 20;
    line-height: 24px;

    @media screen and (max-width: 768px) {
        max-width: 100%;
        margin: 0;
        padding-right: 0;
        border-radius: 10px;
    }

    @media screen and (max-width: 480px) {
        max-width: 100%;
        margin: 0 0 10px 0;
        padding-right: 0;
        border-radius: 10px;
    }
`
