import React from "react";

import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
} from "./SidebarElements";

import { useTranslation, Trans } from "react-i18next";

const lngs = {
  en: { nativeName: "English" },
  es: { nativeName: "Español" },
};

const Sidebar = ({ isOpen, toggle }) => {
  const { i18n } = useTranslation();

  return (
    <>
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
          <CloseIcon />
        </Icon>
        <SidebarWrapper>
          <SidebarMenu>
            {Object.keys(lngs).map((lng) => (
              <SidebarLink
                type="submit"
                key={lng}
                onClick={() => i18n.changeLanguage(lng)}
                disabled={i18n.language === lng}
              >
                {lngs[lng].nativeName}
              </SidebarLink>
            ))}

            <SideBtnWrap>
              <SidebarLink href="https://my.softalmology.com/" target="_blank">
                <Trans i18nKey="login"></Trans>
              </SidebarLink>
            </SideBtnWrap>
          </SidebarMenu>
        </SidebarWrapper>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
