import styled from 'styled-components';

export const PrivacyContainer = styled.div`
margin:20px;

    word-break: break-word !important;
    ul {
        list-style-type: square;
    }
    ul > li > ul {
        list-style-type: circle;
    }
    ul > li > ul > li > ul {
        list-style-type: square;
    }
    ol li {
        font-family: Arial ;
    }
 `;
