import React from "react";
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavLogo,
  NavbarContainer,
  MobileIcon,
  NavMenu,
  NavBtn,
} from "./NavbarElements";
import NavDropdown from "react-bootstrap/NavDropdown";

import { useTranslation, Trans } from "react-i18next";

import { Button2 } from "../ButtonElement";

import logo from "../../images/softalmology-eye.png";
import { getAnalytics, logEvent } from "firebase/analytics";
const lngs = {
  en: { nativeName: "English" },
  es: { nativeName: "Español" },
};

const Navbar = ({ toggle }) => {
  const { i18n } = useTranslation();
  function saveAnalyticEvent(event, data) {
    try{
      const analytics = getAnalytics();
      logEvent(analytics, event, data);
    }catch(e){console.log(e)}
    
  }
  

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">
            <img src={logo} width={50} alt={"Logo de softalmology"} />
            Softalmology
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>

          <NavMenu>
            {/* <NavBtn>
              <NavDropdown
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginRight: "20px",
                }}
                title="Terms"
              >
                <NavDropdown.Item
                  type="submit"
                  key="data-request"
                  onClick={() => (window.location.href = "/data-request")}
                >
                  Data Request
                </NavDropdown.Item>

                <NavDropdown.Item
                  type="submit"
                  key="cookies-privacy-policy"
                  onClick={() =>
                    (window.location.href = "/cookies-privacy-policy")
                  }
                >
                  Cookies and Privacy Policy
                </NavDropdown.Item>
              </NavDropdown>
            </NavBtn> */}
            <NavBtn>
              <NavDropdown
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginRight: "20px",
                }}
                title="Language"
              >
                {Object.keys(lngs).map((lng) => (
                  <NavDropdown.Item
                    type="submit"
                    key={lng}
                    onClick={() => {
                      saveAnalyticEvent("change_language", { language: lng });
                      i18n.changeLanguage(lng)}
                    }
                    disabled={i18n.language === lng}
                  >
                    {lngs[lng].nativeName}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </NavBtn>

            <NavBtn>
              <a
                href="https://my.softalmology.com/"
                target="_blank"
                style={{ textDecorationLine: "none" }}
                rel="noopener noreferrer"
              >
                <Button2
                  secondary
                  href="https://my.softalmology.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    console.log("login");
                    saveAnalyticEvent("login", { login: "login" }
                    )}}
                >
                  <Trans i18nKey="login"></Trans>
                </Button2>
              </a>
            </NavBtn>
              <a
                href="https://calendly.com/softalmology/softalmology-demo"
                target="_blank"
                style={{ textDecorationLine: "none" }}
                rel="noopener noreferrer"
              >
                <Button2
                  success
                  href="https://calendly.com/softalmology/softalmology-demo"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    saveAnalyticEvent("book_demo", { book_demo: "book_demo" }
                    )}}
                >
                  <Trans i18nKey="bookDemo"></Trans>
                </Button2>
              </a>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
