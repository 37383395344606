import React, { useState } from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import DoctorProfileElement from '../components/DoctorProfileSection';
import FooterSection from '../components/FooterSection';
import headerBackground from '../../src/images/headerbgdecoration.png';
import footerBackground from '../../src/images/footerbgdecoration.png';
import { HeaderBg } from '../components/carouselSection/CarouselElements';
function DoctorProfile() {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
      setIsOpen(!isOpen)
    }
  return (
    <>
      
   <Sidebar isOpen={isOpen} toggle={toggle} />
    <Navbar toggle={toggle} />
    <HeaderBg src={headerBackground} alt="headerBackground"/>
     <DoctorProfileElement/>
    <img src={footerBackground} alt="headerBackground" style={{width: '100%', height: '100%'}}/>
     <FooterSection></FooterSection>
     
    </>
  )
}

export default DoctorProfile