import styled from 'styled-components';
// main container has 2 colors, first is blue and second is white and has cards in middle
export const PricingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: auto;
    position: relative;
    z-index: 1;
`;

// pricing container bg image
export const PricingBg = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;

    @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
        height: 100%;
    }

`;

// pricing container title
export const PricingTitle = styled.h1`

    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 24px;
    margin-top: 24px;
    font-weight: 700;
    width: 100%;
    height: auto;
    text-align: center;
`;

//pricing container description
export const PricingDescription = styled.p`
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 0;
    font-weight: 400;
    width: 100%;
    height: auto;
    text-align: center;
`;



// this is the container that holds the cards
export const PricingWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    z-index: 3;
    height: auto;
    margin: 40px auto 50px;
    justify-content: center;
    grid-template-areas: '';

    @media screen and (max-width: 1200px) {
       display:grid;
         grid-template-columns: 1fr 1fr;

    }

    @media screen and (max-width: 768px) {
        display:grid;
        grid-template-columns: 1fr;
      
    }

`;

// invisible text
export const InvisibleText = styled.p`
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 0;
    font-weight: 400;
    width: 100%;
    height: auto;
    text-align: center;
`;


// cards
export const PricingCard = styled.div`
    background: #fff;
    display: flex;

    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    min-width: 350px;
    min-height: 600px;

    padding: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {
        min-width: 300px;
    }
`;

// pricing card title
export const PricingCardTitle = styled.h1`
    font-size: 1.4rem;
    color: #000;
    margin-bottom: 24px;
    font-weight: 400;
    width: 100%;
    
    height: auto;
    text-align: center;
`;

// pricing card info
export const PricingCardInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-bottom: 24px;
   
    width: 100%;
    max-width: 340px;
    padding: 0 24px;
`;

// pricing card price
export const PricingCardPrice = styled.h2`
    font-size: 1.8rem;
    font-weight: 700;
    color: #000;
    margin-bottom: 10px;
`;

export const MiniText = styled.p`
    font-size: 0.8rem;
    color: #000;
    margin-bottom: 0;
    font-weight: 400;
    width: 100%;
    height: auto;
    text-align: justify;
`;



// pricing card features
export const PricingCardFeatures = styled.ul`   
    margin-bottom: 1px;
    list-style: none;
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    text-align: start;
    width: 100%;
    height: 100%;

`;

// pricing card feature
export const PricingCardFeature = styled.li`
    margin-bottom: 10px;
    font-size: 0.9rem;
    color: #000;
    font-weight: 400;
`;


// pricing helper text
export const PricingHelperText = styled.p`
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 0;
    font-weight: 400;
    width: 100%;
    height: auto;
    text-align: center;
`;

export const ToggleButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin-bottom: 2px;
    margin-top: 24px;
 

`;


export const TextToggleButton = styled.h3`
    font-size: 1.2rem;
    color: #fff;
    font-weight: 400;
`;


export const BilledText = styled.h3`
    font-size: 0.9rem;
    color: #000;
    font-weight: 600;
    margin-top: 0;
`;
