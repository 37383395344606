import React, { useState, useEffect } from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import FooterSection from '../components/FooterSection'
import { ProcessTitleSection } from '../components/ProcessSection'

function Process() {
    useEffect(() => {
        document.body.scrollTo(0, 0);
    }, []);
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <ProcessTitleSection/>
            <FooterSection />
        </>
    )
}

export default Process